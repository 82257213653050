.advicesets.advicescenarios {
  .card-body {
    hr {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }
  }

  // scenarios list/table
  .sortable-placeholder {
    background-color: theme-color('primary-medium');
  }

  .handle.ui-sortable-handle {
    cursor: move; // fallback if grab cursor is unsupported
    cursor: grab;
  }

  .ui-sortable-helper {
    background-color: theme-color('primary-light');
    border: 1px solid theme-color('primary');
    border-radius: $border-radius;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, .5);
    transform: rotate(-2deg);
    transition: transform .5ms;
    width: 100%;
  }

  form {
    textarea.text-monospace {
      font-size: $font-size-base;
    }
  }

  // top right of scenario/show
  .scenario-verified {
    .status {
      @include bold-text;
      cursor: pointer;
      font-size: $font-size-xl;
    }
  }

  .card.test-results {
    box-shadow: 0 0 4px rgba(0, 0, 0, .3);
  }

  #testFormBody {
    .form-group {
      border: 1px solid theme-color('primary-light');
      border-radius: $border-radius;
      padding: 1rem;

      .question {
        font-size: $font-size-xl;
        font-weight: bold;
      }

      .btn-secondary {
        font-weight: normal;
      }

      .form-text {
        margin-top: .5rem;
      }
    }

    .isconditional {
      display: flex;

      input {
        margin-left: .5rem;
      }
    }

    code {
      @extend .badge;
      @extend .badge-pill ;
      @extend .badge-secondary;
      font-size: $font-size-xs;
    }
  }

  #jobLogLinesOutputMarkdown, 
  #s3HtmlFileContent {
    overflow-x:scroll; 
    width:100%;
    white-space:nowrap; 

    // make sure links in job log are body text color
    // with taffrail-vars being blue (highlighting the variable)
    li a {
      color: $body-color;
      
      &:hover {
        text-decoration: underline;
      }
    }

    a > taffrail-var {
      color: theme-color('secondary');
    }
  }

  // make sure links in Advice Errors list are blue!
  #s3HtmlFileContent {
    #advice-errors + ol li a {
      color: theme-color('secondary');
    }
  }
}