#modal_markdownPreview .modal-body {
  h1 { font-size: $font-size-base * 1.75; margin-top: 0; }
  h2 { font-size: $font-size-base * 1.5; }
  h3 { font-size: $font-size-base * 1.25; }
  h4 { font-size: $font-size-base * 1.125; }
  h5 { font-size: $font-size-base * 1.25; margin-left: 1rem; }
  h6 { font-size: $font-size-base * 1.125; margin-left: 1rem; }

  h1,h2,h3,h4,h5,h6 {
    margin-bottom: 1rem;
  }

  blockquote {
    @extend .blockquote;
    border: 1px solid $body-color;
    border-radius: $border-radius;
    padding: 1rem;
    width: 90%;
    margin: 1rem auto;

    p:last-child {
      margin-bottom: 0;
    }
  }

  table {
    @extend .table;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  ul, ol {
    margin-bottom: 0;
    padding-left: 2rem;

    li {
      margin-bottom: 1rem;

      > ul {
        margin-top: 1rem;
      }
    }
  }
}

/* bootstrap popover showing alternate advice voices */
.popover-alternates {
  .popover-body & {
    font-size: 90%;
  }

  h6 {
    font-size: 90%;
    font-weight: bold;
    margin: 0 0 .5rem 0;
  }
}

.rule-edit-pane {
  background-color: $white; //theme-color('primary-light');
  // box-shadow: $dropdown-box-shadow;
  border: 1px solid theme-color('primary-medium');
  border-left-width: 3px;
  border-radius: $border-radius;
  margin-top: 0;
  margin-bottom: $node-box-size / 2;
  min-height: 400px;

  // loading "ph" = placeholder
  .ph-item {
    background-color: transparent;
    border: 0 none;
  }

  .editor .form-group-wrapper {
    padding-top: 0;
  }

  .rule-part-edit {
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    min-height: 400px;
    
    header:not(.tabbar) {
      @include bold-text;
      border-bottom: 1px solid theme-color('primary-medium');
      color: $body-color;
      margin-bottom: 1rem;
      padding: 0 $node-box-spacing * 2;

      section {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: 0;
        padding: $node-box-size * .25 0;
      }

      .title {
        font-size: $font-size-lg;
      }

      small {
        font-size: $rule-reserved-name-font-size;
      }
    }

    // advice voices shown as tabs
    /*
    header.tabbar {
      margin-left: -3px;
      margin-top: -1px;

      .nav {
        border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
        padding-left: 10px;
        padding-top: 1px;
        margin-right: -1px;

        > li.nav-item {
          margin-bottom: -$nav-tabs-border-width;

          a.nav-link {
            background-color: theme-color('primary-light');
            border: $nav-tabs-border-width solid $nav-tabs-border-color;
            border-bottom: 0;
            @include border-top-radius($nav-tabs-border-radius);
            @include hover-focus() {
              border-color: $gray-200 $gray-200 $nav-tabs-border-color;
            }
            padding: $nav-link-padding-y $nav-link-padding-x;

            &.active {
              background-color: $white;
              border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg;
              border-bottom: 0 none;
              color: theme-color('primary');
              font-weight: bold;
            }
          }

          &.dropdown {
            align-items: center;
            border-right: 1px solid $nav-tabs-border-color;
            border-top: 1px solid $nav-tabs-border-color;
            border-top-left-radius: $nav-tabs-border-radius;
            border-top-right-radius: $nav-tabs-border-radius;
            display: flex;

            a.nav-link {
              border-right: 0;
              border-bottom: 0 none;
              border-top: 0;
              border-top-right-radius: 0;
              padding-right: $nav-link-padding-x / 4;
            }

            a.nav-link.active + a.dropdown-toggle {
              background-color: $white;
              color: theme-color('primary');
            }

            a.dropdown-toggle {
              background-color: theme-color('primary-light');
              border-bottom: 1px solid $white;
              color: $gray-500;
              margin-bottom: -1px;
              padding: $nav-link-padding-y  $nav-link-padding-x / 2;
              text-align: center;
              width: 2rem;
            }
          }

          &.nav-label {
            a.nav-link {
              background-color: transparent;
              border-color: transparent;
              padding: $nav-link-padding-y ($nav-link-padding-x / 2) $nav-link-padding-y 0;
            }
          }
        }
      }
    }
    */

    // dropzone
    .dz-previews-container .card {
      max-width: 100%;

      img {
        @extend .img-fluid;
        width: unset;
      }
    }

    // all HRs in editor
    hr {
      border-top-width: 3px;
      margin: ($node-box-size / 4) auto;
      width: 33%;

      &.thin {
        border-top-width: 1px;
        margin: ($node-box-size / 2) auto;
        width: auto;
      }
    }

    .form-group-buttons {
      display: none;
    }

    .form-user-submit + .form-user-submit {
      margin-top: 2rem;
    }
  }
  
  .rule-part-edit__content {
    display: flex;
    flex-direction: column;
    width: 100%;

    &.is-inactive {
      header {
        @include gradient-striped($gray-200);
        background-size: 1.5rem 1.5rem;
      }
    }
  }

  // icons on top right of editor
  .action-row {
    display: flex;
    font-size: $font-size-lg;

    > div,
    > a {
      margin-left: 1.3rem;
    }

    > a {
      color: theme-color('primary');
    }
  }

  .rule-part-edit__content-aside,
  .rule-part-edit__content-detail {
    height: 100%;
  }

  .rule-part-edit__content-aside .tab-content,
  .rule-part-edit__content-detail {
    padding: $node-box-spacing ($node-box-spacing * 2);
  }

  .rule-part-edit__content-detail {
    > .row,
    > section.row + div > .row {
      margin-bottom: 1rem;
    }

    > section.row {
      margin-bottom: 0;
    }

    > .row > .col-12 > form.form-group {
      margin-bottom: $form-group-margin-bottom * .6;
    }

    .field-control-btns {
      font-size: 90%;

      .nav-link {
        padding: .2rem .4rem;

        &:hover {
          background-color: $gray-200;
        }
      }

      &.field-control-btns--low {
        margin-top: 3px;
      }
    }
  }

  .alternate-voice {
    .detail {
      padding: .25rem 8px .25rem 4px;

      h6 {
        color: $input-placeholder-color;
        font-weight: $font-weight-bold;
        margin-bottom: 4px !important;
      }

      a {
        color: $body-color;

        &:hover {
          color: theme-color('primary');
        }
      }
    }
  }

  .rule-part-edit__content-aside {
    border-top: 1px solid theme-color('primary-medium');

    .tab-content {
      margin-bottom: 10px;
    }

    // tabs at bottom of rule part editor
    .nav-tabs,
    .tab-content {
      background-color: transparent;
    }

    .tab-content .card {
      background-color: transparent;
      margin-bottom: 0;
    }

    .tab-content .card-header {
      padding-top: 0;
    }
    
    .variableList-pane {
      table {
        width: 100%;

        th,
        td {
          padding: 7px !important;
        }

        td {
          a {
            color: $body-color;

            &:hover {
              text-decoration: none;
            }
          }

          code {
            white-space: nowrap;
            color: $body-color;
          }

          &.dataType i {
            font-size: $font-size-base;
          }
        }

        @media (min-width: breakpoint('md')) {
          td:nth-child(1),
          td:nth-child(2) {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 1px;
          }
        }
      }
    }
  }

  .markdown-preview-btn {
    font-size: 90%;
    margin-top: -.5rem;
    text-align: right;
  }

  // attachments
  .visualizations {
    &.list-group {
      .list-group-item {
        padding: $input-padding-y-sm $input-padding-x-sm $input-padding-y-sm 4px;
      }
    }
  }
  .dropzone {
    .supplements.dz-previews-container.card-deck {
      margin: 0;

      .card {
        margin: 0;
        padding-left: 0;

        &.card-border.btn--add-file-has-none {
          border-color: $white;

          &:not(.disabled):hover {
            border-color: $input-border-color;
          }
        }

        &.disabled {
          cursor: not-allowed;
        }
      }

      .card-header {
        @extend .form-control-sm;
        color: $input-placeholder-color;
        font-weight: $font-weight-base;
        height: 28px;
        margin: 0;
        padding: $input-padding-y-sm $input-padding-x-sm $input-padding-y-sm 4px;
      }
    }
  }
}
