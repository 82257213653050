h1 {
  small {
    font-size: $font-size-lg;
  }
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1;
}

strong, b {
  @include bold-text;
}

.text-sm {
  font-size: .765rem;
}