.explore {

  .be-top-header .be-navbar-header {
    width: 230px;
    flex: 0 0 230px;
  }

  .navbar-alt {
    // on smallest of screens hide user profile button
    @media (max-width: breakpoint('md')) {
      .be-user-nav {
        display: none;
        visibility: hidden;
      }
    }
  
    // on < large viewports tighten up the top navbar
    @media (max-width: breakpoint('lg')) {
      .navbar-brand {
        margin-right: 0 !important;
      }
  
      .navbar-nav {
        .nav-link {
          padding: 0 12px !important;
          white-space: nowrap;
        }
      }
  
      .be-user-nav {
        a.btn {
          max-width: 120px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .navbar-nav > li > {
      a.nav-link {
        color: $white;
      }
    }
  }

  $text-shadow: 2px 2px 2px rgba(0, 0, 0, .3);

  .be-nosidebar-left .be-content {
    margin: 0 auto !important;
  }

  .be-content {
    background-color: unset;
  }

  .card-container {
    @media (min-width: breakpoint('lg')) {
      display: flex;
    }
  }

  .card {
    display: flex;
    width: 100%;

    .card-footer {
      background-color: transparent;
      font-size: $font-size-sm;
    }

    &.card-has-bg {
      color: $white;

      &::before {
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: $border-radius;
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, .4);
        content: '';

        // different heights at different breakpoints
        min-height: 250px;
        @media (min-width: breakpoint('lg')) and (max-width: breakpoint('xxl')) {
          min-height: 370px;
        }
        @media (min-width: breakpoint('xxl')) {
          min-height: 285px;
        }

        object-fit: cover;
      }

      .card-title {
        margin-bottom: 26px;
        text-shadow: $text-shadow;
      }

      .card-subtitle {
        color: $gray-300;
        text-shadow: $text-shadow;
      }

      .card-text {
        margin-bottom: 1.5rem;
        text-shadow: $text-shadow;
      }

      .card-btn-try {
        text-shadow: $text-shadow;

        small > a {
          color: $gray-300;

          &:hover {
            color: $gray-400;
          }
        }
      }
    }

    &:not(.card-has-bg) {
      &:hover {
        background-color: theme-color('primary-light');

        .card-title {
          color: theme-color('primary');
        }
      }
    }

    .card-img-overlay {
      p {
        font-size: $font-size-base;

        @media (min-width: breakpoint('xxl')) {
          font-size: $font-size-lg;
        }
      }
    }

    .card-title {
      @include bold-text;
    }

    .card-subtitle {
      font-size: $font-size-sm;
    }

    .card-btn-try {
      small {
        margin-top: 6px;

        a:hover {
          color: $gray-300;
        }
      }
    }

    .card-tags {
      margin-top: 1rem;

      li {
        a {
          margin-right: 4px;
        }
      }

      .badge {
        color: $gray-600;
        cursor: text;
        font-size: $font-size-xs;
        font-weight: normal;

        &[data-btntype=expandTags] {
          cursor: pointer;
        }
      }
    }
  }

  .bg-gray {
    background-color: #eee;
  }

  header {
    margin-bottom: 2rem;
    text-align: center;

    h3 {
      border-bottom: 2px solid $gray-400;
      display: inline-block;
      margin-bottom: 0;
      padding: 0 3rem 1rem;
      width: auto;
    }
  }

  .back-top {
    font-size: $font-size-sm;
    margin-top: -1.25rem; // pull link up under cards

    a {
      color: $gray-500;

      &:hover {
        color: $gray-700;
      }
    }
  }

  .category-jump {
    border: 1px solid $gray-500;
    text-decoration: none;
  }

  footer {
    color: $gray-600;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    margin-top: 6rem;

    a {
      color: $gray-600;

      &:hover {
        text-decoration: underline;
      }
    }

    .made-with small {
      display: block;
      text-align: right;
    }
  }
}