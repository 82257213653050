.tf-card--type-rule-tree-visual {
  height: 100%;

  .modal-content {
    height: 95%;
  }

  .modal-body {
    width: 100%;
    height: 100%;
  }

  #treeContainer{
    position: relative;
  }

  #mini-svg {
    margin-left: 30px;
  }

  .minimap {
    fill: white;
    stroke: black;
  }

  .node {
    cursor: pointer;
  }

  .node rect {
    opacity: 0.35;
    stroke: black;
    stroke-width: 1.2px;
  }

  .mininode rect {
    opacity: 0.35;
    stroke: lightgrey;
    stroke-width: 0.4px;
  }

  .link {
    fill: none;
    stroke: grey;
    stroke-width: 1px;
  }

  .mini-link {
    fill: none;
    stroke: lightgrey;
    stroke-width: 0.1px;
  }

  g.brush>.handle {
    display: none;
  }

  .overlay {
    pointer-events: none;
  }

  .caption {
    position: absolute;
    width: 20em;
    color: white;
    padding: 1em;
    background-color: #0e242b;
    border-color: #0e242b transparent;
    border-radius: 5px;
    pointer-events: none;
  }

  .caption:before {
    position: absolute;
    top: -15px;
    content: '';
    width: 0px;
    height: 0px;
    border-color: inherit;
    border-width: 0 15px 15px;
    border-style: solid;
  }

  .caption h3 {
    color: white;
    font-size: 18px;
    margin: 0px;
  }

  .caption p {
    font-size: 14px;
    margin: 0px;
  }
  
}