// hide sidebar toggle by default...
.be-toggle-left-sidebar {
  display: none !important;
}

// navbar
.navbar {
  .fixed-top {
    z-index: $zindex-loading-mask + 1;
  }

  .navbar-brand {
    width: unset !important; // let the logo img fill the space
  }

  .be-user-nav {
    li.dropdown > a .user-name {
      display: inline-block; // show user name
  
      // firm name beneath owner
      .user-owner-name {
        color: theme-color("tertiary");
        display: block;
        font-size: $font-size-xs;
        line-height: 1;
      }
    }
    .dropdown-toggle {
      .icon {
        font-size: 1.25rem;
        vertical-align: middle;
      }
    }
  }

  // custom colors for algolia search box
  .search-container {
    height: unset;
    margin-right: 5rem;

    .search-input-container {
      @media (min-width: breakpoint('sm')) {
        display: flex;
      }
      align-content: center;
      align-items: center;
      width: 100%;

      .aa-Autocomplete {
        width: 100%;
      }

      input {
        background-color: #262c61;
        border-color: theme-color('primary');
        color: $gray-100;
        padding-right: 2.25rem;
        position: relative;
  
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: $gray-500;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: $gray-500;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: $gray-500;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: $gray-500;
        }
      }
      // slash image
      img {
        position: absolute;
        right: 1rem;
      }
    }
  }

  .intercom_messenger_launcher {
    margin-right: 10px;

    .icon {
      font-size: 1.25rem;
      vertical-align: middle;
    }
  }

  // positioning for popover menus
  .mystuff,
  .be-notifications,
  .be-connections {
    left: auto;
    right: 25px;
  }

  // scrolling notifications + mystuff
  .be-scroller-notifications {
    height: 222px;
    position: relative;
  }

  .mystuff .nav .nav-link {
    @extend .dropdown-item;
    padding: 8px 20px;
    white-space: normal;
  }
}
