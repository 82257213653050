$border-width: 1px;
$node-box-size: 40px;
$node-width: $node-box-size * 5.45; //~411px, great for medium+ screens
$node-box-spacing: $node-box-size / 8;
$connector-width: 1px;
$connector-border-color: $gray-400;
$connector-height: $node-box-size / 2;
$indent-connector: $node-box-size * 1.5;
$indent-children-nodes: $node-box-size;
$rule-reserved-name-font-size: 70%;

// generic helper
// TODO: Move for use elsewhere on app?
.field-label {
  @include bold-text;
  color: $gray-600;
  font-size: $font-size-sm;
  margin-bottom: 5px;
}

// Rule#show has white background
body.rules {
  background-color: $white;
  
  .popover {
    max-width: 400px !important;
	}
}

.rule-header {
	background-color: $gray-100;
	border-bottom: 1px solid $gray-300;
	color: $gray-600;
	padding-left: 70px;
  
  a.be-toggle-right-sidebar {
    color: $gray-600;

    &:hover {
      color: theme-color('primary');
    }

    i {
      margin-left: .2rem;
    }

    // the <body> el gets a new ".open-right-sidebar" class
    // so change the "Show sidebar •••" text
    // to be colored with a rotated icon and "hide" instead of "show"
    body.open-right-sidebar & {
      color: theme-color('secondary');

      span { display: none; }
      &:before { content: "Hide"; }

      i {
        @extend .fa-rotate-90;
      }
    }

    // when the screen is wide enough, the sidebar is opened automatically
    body.open-right-sidebar--forced & {
      display: none;
    }
  }

	.rule-header-group {
		margin-right: 1.5rem;
	}

	.lbl {
		font-size: 90%;
		margin-right: .5rem;
		padding: .8rem 0;
	}

	.btn-sm {
    font-size: 90%;
    
    &.active {
      color: theme-color('black') !important;
    }
  }

  .btn-sm:not(.dropdown-toggle) {
		color: $gray-600;
  }
  
  .dropdown-menu {
    .dropdown-item {
      &.active {
        color: $white;
      }
    }
  }
}

// sidebar

// on non-mobile screens, the <body> has these classnames
// so shrink the main content area to make room for open sidebar
body.open-right-sidebar.open-right-sidebar--forced {
  main > .main-content {
    margin-left: 0;
    width: calc(100% - 289px)
  }
}

.rule-show,
.dropdown-menu {
  // color Delete actions with red
  [data-btnType='Delete'],
  .btn-allow-delete-rule {
    color: theme-color('danger') !important;

    &:hover {
      background-color: theme-color('danger');
      color: $white !important;

      .icon {
        color: $white;
      }
    }

    .icon {
      color: theme-color('danger');
    }
  }

  .dropdown-item.dd-active-focus {
    &:hover {
      background-color: theme-color('secondary');
    }

    &.disabled {
      font-weight: normal;
      i {
        color: $gray-600;
      }
    }
  }
}

.rule-show {
	padding: 0 10px;

  .rule-node__type-icon {
    > .badge {
      display: none;
      margin: auto;

      &.shown {
        display: flex !important;
      }
    }
  }

  .gutter {
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 0;
    z-index: $zindex-dropdown;
  
    &.gutter-horizontal {
      &:hover {
        background-color: transparent;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
        cursor: col-resize;
      }
    }
  }

  // make room for gutter
  $heightOfTopOfPage: 240px;

  .rule-tree {
    padding-right: 10px;

    // perfectscrollbar
    position: relative;
    height: calc(100vh - #{$heightOfTopOfPage});
    padding-right: 18px;

    .rule-tree--branches {
      padding-bottom: 40px !important;
    }
  }
  .rule-editor {
    padding-left: 3px;

    // perfectscrollbar
    position: relative;
    height: calc(100vh - #{$heightOfTopOfPage});
  }

  .rule-tree {
    .ui-sortable-helper {
      border-left-width: 0 !important;
      transform: rotate(-2deg);
      transition: transform .5ms;

      .rule-node-connector {
        height: 0 !important;
        visibility: hidden;
      }

      .rule-node {
        box-shadow: $dropdown-box-shadow;
      }

      .node-actions {
        display: none !important;
      }
    }

    .ui-state-highlight {
      $light-blue: lighten(theme-color('info'), 35%);
      background-color: $light-blue;
      border-color: $light-blue;
      margin-top: $node-box-size / 4;
    }

    .ui-state-highlight--can-drop {
      border: 2px dotted theme-color('primary-medium');
      min-height: $node-box-size !important;

      li {
        border-left-width: 0 !important;
      }
    }
    
    // parent <ul>
    .rule-tree--branches {
      list-style: none;
      margin: 0;
      padding: 0;

      // sibling level, excluding calling rules and topic (1st) nodes
      li:not(.rule-tree--branches__calling-rule):not(.rule-tree--branches__topic):not(.nav-item):nth-child(n+2) {
        margin-left: $node-box-size / 2;
      }
      
      li {
        // all children of active node should be light purple
        // unless they are active themselves
        .rule-node--active + ul + ul {
          .rule-node--item {
            .rule-node {
              background-color: theme-color('primary-light');
              border-color: theme-color('primary-medium');
              font-weight: $font-weight-normal;

              a {
                color: theme-color('primary');
              }

              // active "child" of condition
              &.rule-node--active {
                background-color: theme-color('primary-medium');

                a {
                  &.dropdown-toggle {
                    color: $white;
                  }
                  
                }
              }
            }
          }
        }
        
        // visibility changed on-hover
        .node-sort-handle-icon,
        .node-expand-collapse-handle-icon {
          display: none;
        }
        
        // sortable nodes
        &[data-is-sortable]:not(.ui-sortable-helper) {
          .rule-node__type-icon {
            &:hover {
              .node-icon {
                display: none;
              }

              .node-sort-handle-icon {
                cursor: move;
                cursor: grab;
                display: flex;
              }
            }
          }
        }

        // conditions can be expanded/collapsed
        section[data-is-collapsible] {
          .rule-node__icon:hover {
            > .seq,
            > .icon:first-child {
              display: none;
            }

            > .node-expand-collapse-handle-icon {
              cursor: pointer;
              display: flex;
            }
          }
        }
      }

      // child level
      > li > div > ul {
        list-style: none;
        margin: 0;
        padding: 0;

        > li {
          
          margin-left: ($node-box-size / 2) - $connector-width !important;
          padding-left: $node-box-size / 2;

          &:not(.rule-tree--branches__lastcondition) {
            border-left: $connector-width solid $connector-border-color;
          }

          .rule-node-connector {
            height: $connector-height / 2;
          }
        }
      }

      .rule-tree--branches__branch {
        min-height: 1px;
        margin-top: -1px;
      }

      > li.rule-tree--branches__condition_always {
         > div > ul > li {
          border-left-width: 0 !important;
          margin-left: 0 !important;
          padding-left: 0 !important;
        }
      }
    }
  }

  // special CSS to allow BS tooltips to work
  // on disabled elements
  .btn-allow-delete-rule.disabled {
    cursor: not-allowed;
    pointer-events: all;
  }

  // each node is a <section> in an <li>
  .rule-node {
    align-items: stretch;
    background-color: $gray-100;
    border-color: $connector-border-color;
    border-radius: $border-radius;
    border-style: solid;
    border-width: $border-width;
    color: $body-color;
    display: flex;
    justify-content: space-between;
    min-height: $node-box-size;
    position: relative;
    width: 100%;

    &:not(.rule-node--active):hover,
    &:not(.rule-node--active):hover + ul .rule-node {
      background-color: theme-color('primary-light');
      border-color: theme-color('primary-medium');
    }

    // give rule nodes button-like feeling on-press
    &:not(.rule-node--arl-actions):active {
      box-shadow: inset 0 0 3px $gray-400;
      outline: none;
    }

    &.is-invalid {
      border-color: $warning;
      border-style: dashed;

      &:hover {
        border-color: $warning;
      }
    }

    // top level nodes (rule + its conditions) are bold always
    &.rule-node--topic,
    &.rule-node--condition {
      @include bold-text;
    }

    &.rule-node--condition_always {
      border: 0 none;
      height: 0;
      min-height: 0;
      width: 0;
      visibility: hidden;
    }

    &:not(.rule-node--arl-actions):hover {
      .node-actions {
        display: block;
      }
    }

    &:not(.rule-node--arl-actions) .node-actions {
      background-color: $white;
      border: 1px solid $gray-400;
      border-radius: $border-radius;
      box-shadow: 0 0 3px $gray-400;
      display: none;
      font-weight: normal;
      position: absolute;
      right: 1px;
      top: -($node-box-size * .65);

      &.open {
        display: block;
      }

      &[data-entity-type='topic'].no-calling-rules {
        top: 4px;
      }

      .nav-item {
        margin-left: 2px !important;

        &:first-child {
          margin-left: 0 !important;
        }

        .nav-link {
          padding: .25rem .6rem;

          &:not([data-btntype="Delete"]):not(.btn-allow-delete-rule):not(.dropdown-toggle):hover {
            background-color: $gray-100;
          }
        }
      }
    }
  }

  .rule-node__handle {
    color: $body-color;
    cursor: pointer !important;
    display: flex;
    flex-grow: 1;
    width: calc(100% - 43px);
  }

  .rule-node__name {
    align-items: center;
    display: flex;
    flex: 1;
    line-height: $display-line-height;
    overflow: hidden; // hidden b/c Windows 
    padding: 3px $node-box-spacing 3px 0;
    width: 100%;

    .formula {
      font-family: $font-family-monospace;
      font-size: 85%;
      font-weight: $monospace-font-weight;
      line-height: 1.4;
    }

    &.rule-node__name-with-detail {
      align-items: flex-start;
      flex-direction: column;
      justify-content: start;
      margin-top: auto;
      margin-bottom: auto;
      // for sub-text next to name such as calling rule ordinal
      small {
        font-size: $font-size-sm;
        margin-top: .25rem;

        &.opt-condition {
          font-size: $font-size-xs;
        }
      }
    }

    .advice-text {
      line-height: $line-height-base;
    }
  }

  // topics and conditions aren't sortable, use default pointer
  .rule-node--topic,
  .rule-node--condition {
    .rule-node__icon {
      cursor: default !important;
    }
  }

  .calling-rule .rule-node__handle-call {
    .rule-node__name.has-sub-text {
      align-items: flex-start;
      flex-direction: column;
      justify-content: start;
      margin-top: auto;
      margin-bottom: auto;
      // for sub-text next to name such as calling rule ordinal
      small {
        margin-top: .25rem;
      }
    }
  }

  .rule-node__icon,
  .rule-node__type-icon {
    color: $body-color;
    min-height: $node-box-size - ($border-width * 2);
    width: $node-box-size - ($border-width * 2);

    &:not(.hidden) {
      display: flex;
    }

    i {
      align-items: center;
      display: flex;
      font-size: $font-size-xl;
      font-style: normal;
      justify-content: center;
      width: 100%;
    }

    &.rule-node__icon--count {
      align-items: center;
      justify-content: flex-end;
      width: ($node-box-size * 1.5) - ($border-width * 2);

      &:not(.hidden) {
        display: flex;
      }

      i {
        margin-left: 2px;
        margin-right: $node-box-size / 4;// center # and down caret above ellipsis icon beneath
        width: auto;
      }
    }
  }

  .rule-node--active {
    background-color: theme-color('primary-medium');
    border-color: theme-color('primary-medium');
    color: theme-color('primary');
    position: relative;

    &:not(.rule-node--active__has-active-child):after {
      @extend %fa-icon;
      @extend .far;

      color: theme-color('primary');
      content: fa-content($fa-var-angle-right);
      font-size: 29px;
      position: absolute;
      right: -18px;
      top: 4px;
    }

    .rule-node__handle,
    .rule-node__icon,
    .rule-node__type-icon,
    .rule-node__name {
      color: theme-color('primary') !important;
    }

    .rule-node__name {
      @include bold-text;

      .formula {
        font-weight: 600; // Fira Code Bold
      }
    }

    &.rule-node--active__has-active-child {
      background-color: theme-color('primary-light');
      color: theme-color('primary');

      .rule-node__icon,
      .rule-node__type-icon {
        color: theme-color('primary') !important;
      }

      .rule-node__name {
        color: theme-color('primary') !important;
        font-weight: $font-weight-normal !important;
      }
      
    }
  }

  // inactive/commented out node
  .rule-node.is-inactive,
  .rule-node.is-inactive + ul .rule-node {
    // background-color: $gray-100;
    @include gradient-striped($gray-200);
    background-size: 1.5rem 1.5rem;
    border-color: $gray-300;
    color: $gray-100;
  
    &:hover,
    &:hover + ul .rule-node {
      @include gradient-striped($gray-300);
      border-color: $gray-400;
      color: $gray-400;
    }
  
    .rule-node__icon,
    .rule-node__type-icon {
      color: $gray-500  !important;
    }
  
    .rule-node__name {
      color: $gray-500;
    }
  }

  // inactive parent but selected/highlighted/editing child node
  .rule-node.is-inactive + ul .rule-node.rule-node--active,
    // inactive/commented out but selected/highlighted/editing node
  .rule-node.is-inactive.rule-node--active {
    // @include gradient-striped(theme-color('primary-medium'));
    // background-size: 1.5rem 1.5rem;
    
    .rule-node__name {
      color: theme-color('primary');
      font-weight: normal;
    }

    .rule-node__icon,
    .rule-node__type-icon {
      color: theme-color('primary') !important;
    }
  }

  .rule-node--add,
  .rule-node--calling-rule {
    background-color: $gray-100;
    border-color: $gray-200;

    &:hover {
      border-color: theme-color('primary-medium');
    }

    .rule-node__handle,
    .rule-node__icon {
      color: $gray-600;
    }

    .rule-node__handle {
      color: $gray-600;

      &:hover {
        color: $body-color;

        .rule-node__icon {
          color: $body-color;
        }
      }
    }

    .rule-node__icon--count {
      &:hover {
        color: theme-color('primary');
      }
    }
  }

  .rule-node--calling-rule.no-calling-rules {
    border-color: $gray-100;

    &:hover {
      background-color: $gray-100;
      border-color: $gray-100;
    }

    .rule-node__handle {
      cursor: default !important;
      font-style: italic;
    }

    .rule-node__icon,
    .rule-node__handle,
    .rule-node__handle:hover {
      color: $gray-500;

      i {
        color: $gray-500;
      }
    }
  }

  .rule-node.rule-node--arl-actions {
    background-color: transparent;
    border-color: $gray-200;

    &:hover {
      background-color: transparent !important;
      border-color: $gray-200;
    }

    .rule-node__handle,
    .rule-node__icon {
      color: $gray-300;
      cursor: default !important;
    }

    .nav-item {
      margin-right: .25rem;
    }

    .nav-link {
      border: 1px solid transparent;
      border-radius: $border-radius;
      color: $body-color;
      padding: .5rem .75rem;

      &:hover {
        // border-color: theme-color('secondary');
        border-color: $gray-500;
        box-shadow: 0 0 3px $gray-400;
        color: theme-color('secondary');
      }
    }
  }

  .rule-node-connector {
    border-left: $border-width solid $connector-border-color;
    height: $connector-height / 2;
    margin-left: ($node-box-size / 2) - $connector-width;
    width: $node-box-size;

    @media (min-width: breakpoint('xl')) {
      height: $connector-height;
    }

    &.rule-node-connector--short {
      height: $connector-height / 2;
    }

    &.rule-node-connector--endnode {
      border-left-color: $connector-border-color;
      border-left-style: dotted;
      border-left-width: $border-width;
      height: $node-box-size / 2;
    }

    &.rule-node-connector--calling-rule {
      border-left-color: $gray-200;
      border-left-style: dotted;
      border-left-width: $border-width;
      height: $node-box-size / 4;
    }
  }
}
