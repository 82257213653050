fieldset legend {
  font-size: $font-size-base;
}

legend.text-sm {
  font-size: $font-size-sm !important;
}

form {

  @import "../vendor/float.label";

  label sup {
    font-size: $font-size-sm;
    top: 0;
  }
  
  .form-control--code {
    font-family: $font-family-monospace;
    font-size: $font-size-content;
    font-weight: $monospace-font-weight;
  }

  .form-control--field-visibility-public {
    border: 1px dotted theme-color('secondary');
    border-radius: $border-radius;
  }

  .form-text {
    font-size: $font-size-sm;
  }

  .form-group-buttons {
    margin-top: $input-padding-y;
  }

  .invalid-feedback {
    ul {
      margin-left: 0;
      padding-left: 1rem;
    }
  }
}

.dropdown-menu {
  &.textcomplete-dropdown {
    // this is stupid but necessary b/c of the way
    // jquery-textcomplete sets the z-index inline 😭
    z-index: $zindex-dropdown-textcomplete !important;
  }
}

.form-group {
  legend {
    font-size: $font-size-lg;
  }

  .form-check {
    margin-bottom: 0;
  }

  .radio-choices {
    .form-group {
      margin-bottom: $form-group-margin-bottom / 2;
    }
  }
}

.modal {
  .well {
    padding-left: 0;
    padding-right: 0;

    ul {
      ul {
        padding-left: 10px;
      }
    }
  }
}

// styling for Countable character count 
.form-group {

  // the counter el is inside the <label> element
  // so align them in a flexbox and spread the space between
  label {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    
    .form-field-live-counter {
      font-size: $font-size-xs;
      text-align: right;

      &--warning {
        @include bold-text;
        color: theme-color('warning');
      }

      &--redline {
        @include bold-text;
        color: theme-color('danger');
      }
    }

    > a {
      color: $gray-600;
      font-size: $font-size-sm;

      &:hover {
        color: theme-color('primary');
      }
    }

    > span.danger {
      @include bold-text;
      color: theme-color('danger');
    }
  }
}

.selectize-control {
  .selectize-dropdown,
  .selectize-input {
    .caption {
      @extend .text-sm;
      @extend .text-muted;
      display: block;
      line-height: $line-height-base;
    }
  }

  &.multi {
    .selectize-input {
      &.has-items {
        > div {
          padding-right: 1rem;
        }
      }
    }
  }
  
  &.variable-datatype {
    .selectize-input {
      input {
        height: 0;
        text-indent: -9999px !important;
        visibility: hidden;
        width: 0 !important;
      }
    }
  }
}

.dropzone {
  position: relative;

  .drop-helper {
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: $white;
    opacity: .9;
    position: absolute;
    top: 0;
    left: 0;
    z-index: $zindex-loading-mask;
  }

  &.dz-drag-hover:not(.dz-max-files-reached) {
    .drop-helper {
      display: flex;
    }
  }

  .dz-default.dz-message {
    display: none;
    visibility: hidden;
  }

  .btn--add-file {
    cursor: pointer;
  }

  .dz-previews-container,
  .dz-previews-container-aside {
    $thumbSize: 148px;

    &.card-deck {
      margin-left: -6px;
      margin-right: -6px;

      .card {
        margin-left: 6px;
        margin-right: 6px;
      }
    }

    .card {
      border-width: 1px;
      max-width: $thumbSize + 2; // account for border width
      height: unset !important;

      img {
        width: $thumbSize;
        height: $thumbSize;
      }

      .far {
        text-align: center;
      }

      &.btn--add-file-has-none {
        align-items: baseline;
        flex-direction: row;
        padding-left: 6px;
        max-width: unset;
        width: 100%;
        .card-title {
          margin: 0;
        }
        .card-footer {
          display: none;
          visibility: hidden;
        }
      }

      &.btn--add-file-has-some {
        .far {
          font-size: 400%;
          padding-top: 50px;
          width: $thumbSize;
          height: $thumbSize;
        }
      }

      // this is funky because of beagle's card style overrides
      h6.card-title {
        @extend .h6;
      }

      .card-body,
      .card-footer {
        padding-left: $card-body-horizontal-padding / 3;
        padding-right: $card-body-horizontal-padding / 3;
      }

      .card-body {
        padding-bottom: $card-body-horizontal-padding / 3;
      }

      .card-footer {
        background-color: transparent;
        padding-top: 0;
      }
    }
  }

  .dz-previews-container-aside {
    .card img {
      height: unset;
      max-height: 125px;
      max-width: 85px;
      width: unset;
    }

    .card-title {
      font-size: 16px;
      font-weight: 300;
    }
  }
}

.form-group--dropzone {
  @extend .progress;
  background-color: transparent;
  display: block;
  font-size: $font-size-base;
  height: initial;
  
  .form-group--dropzone__droparea {
    @include bold-text;
    @extend .progress-bar;
    @extend .progress-bar-striped;
    background-color: theme-color('primary-medium');
    border: 2px dotted $gray-200;
    border-radius: $border-radius;
    color: theme-color('primary');
    padding: 6.5px;

    &.progress-bar-animated {
      color: $white;
    }
  }

  &.active {
    .form-group--dropzone__droparea {
      border-color: theme-color('success');
      border-style: solid;
      color: theme-color('success');
    }
  }

  .btn {
    position: relative;
    overflow: hidden;

    input[type=file] {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      border: solid transparent;
      width: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
}

.inline-editor-form:not(a) {
  cursor: text;
  display: inline-block;
  width: 100%;
}
