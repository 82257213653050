#apiKeySecretString {
	background-color: $gray-300;
	border-radius: $border-radius;
	cursor: pointer;
	font-family: $font-family-monospace;
	font-size: 120%;
	font-weight: $monospace-font-weight;
	padding: 8px;
	text-align: center;

	&:hover {
		background-color: $gray-400;
	}
}