// editor header for rulesets
.scroll-fixed {
	margin-bottom: 2rem;
}

.scrolled-below-header {
	.scroll-fixed {
		$speed: 0.3s;
		box-shadow: 4px 0 6px rgba(0,0,0,0.3);
		margin-bottom: 0;
		position: fixed;
		transition: $speed;
		z-index: $zindex-dropdown - 1;

		.advicebuilder-header {
			padding-bottom: 5px;
			padding-top: 5px;
			transition: $speed;
		}

		.header-chip {
			height: 36px;
			font-size: 1rem;
			width: 36px;
		}

		.header-ruleset-reservedName {
			font-size: 1.5rem;
			transition: $speed;
		}

		.header-top-row,
		.header-main-nav {
			display: none !important; //override inline flex
			transition: $speed;
		}

		.rule-header {
			margin-bottom: 0;
			transition: $speed;
		}
	}

	.rule-show {
		padding-top: 174px; // height of .scroll-fixed content
	}
}

.advicebuilder-header {
	background-color: $white;
	border-bottom: 1px solid $gray-300;
	padding: 15px 10px 0 10px;

	.header-chip {
		align-items: center;
		background-color: $gray-100;
		border-radius: 4px;
		display: flex;
		font-size: 1.3rem;
		justify-content: center;
		height: 48px;
		margin-right: 1rem;
		text-align: center;
		width: 48px;

		i {
			color: theme-color('secondary') !important;
		}
	}

  .header-top-row {
    .header-family,
    .header-category,
    .header-tags {
      font-size: 70%;

      a { 
        border: 1px solid $gray-300;
        border-radius: $border-radius;
        color: $gray-600;
        padding: 3px;
        &:hover {
          color: theme-color('primary');
        }
      }
    }

    [class^="header-"]:not(:first-child) {
      margin-left: 1rem;
    }
  }

	.header-main-row {
		margin-top: .3rem;
	}

	.header-ruleset-reservedName {
		color: theme-color('black');
		cursor: pointer;
		font-size: 1.75rem;
		font-weight: 300;
		line-height: normal;
		margin: 0 1rem 0 0;
		max-width: 80%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.dropdown-toggle {
		color: $gray-600;
		&:hover {
			color: theme-color('primary');
		}
	}

	.header-option-icons {
		ul { 
			margin: 0;
		}

		li {
			font-size: 110%;
			margin-left: 1.10rem;
		}

		.alert {
			white-space: nowrap;
		}

		a {
			color: $gray-600;
			&:hover {
				color: theme-color('primary');
			}
		}
	}

	.header-state {
		font-size: 80%;
		line-height: 1;
		margin: 0;
		padding: 4px;
		text-transform: uppercase;

		&.alert-default {
			background-color: theme-color('primary-medium');
			color: theme-color('primary');
		}
	}

	.header-main-nav {
		ul {
			@extend .list-unstyled;
			margin: 0;
		}

		$spacing: .75rem;

		li {
			&:not(:last-child) {
				margin-right: 2rem;
			}

			margin-top: .5rem;
			padding: 0 0 $spacing 0;

			a:not(.dropdown-item) {
				@include bold-text;
				color: $gray-600;
				padding: 0 0 $spacing 0;

				&:hover {
					border-bottom: 2px solid $gray-300;
					color: theme-color('primary');
				}

				&.active {
					border-bottom: 2px solid theme-color('primary');
					color: theme-color('primary');
        }
        
        span.data {
          color: $gray-500;
          font-weight: normal;
        }
			}
		}
  }
  
  .dropdown-menu {
    .dropdown-item {
      &.active {
        color: $white;

        &:hover {
          background-color: theme-color('primary');
        }
      }
    }
  }
}

// adviceset details modal
.modal {
  .row {
    margin-bottom: .75rem;
  }

  // left column
  .col-3 {
    align-self: center;
    vertical-align: top;
  }

  .details-modal-heading {
    color: $gray-600;
    font-size: .75rem;
    margin: 0;
  }

  .inline-editor-form {
    margin: 0;
  }

  .field-visibility {
    color: $gray-600;
    display: inline-block;
    margin-left: .25rem;

    &.public {
      color: theme-color('secondary');
    }
  }

  .field-visibility-public {
    border: 1px dotted theme-color('secondary');
    border-radius: $border-radius;
    padding: .25rem;
  }
}
