// These are overrides to /src/beagle/src/sass/includes/elements/widgets/_user-timeline.scss
.user-timeline {
  
  &-no-style{
 		&:before{
 			position: unset;
 		}

 		&:after{
 			position: unset;
 		}

 		> li {
 			padding-bottom: 35px;
 			padding-left: 0px;

 			&:last-child{
 				padding-bottom: 15px;
 			}

 			&:before{
 				border: none;
 			}
 		}
  }
	 
	&-compact{
		> li{
			padding-bottom: 0px;

			&:last-child{
				padding-bottom: 25px;
			}
		}
	}
}
