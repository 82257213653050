@mixin bold-text() {
  // font-family: $font-family-sans-serif-semibold;
  // font-weight: normal;
  font-weight: $font-weight-bold;
}

@mixin full-height() {
  height: 100%;
  min-height: 100%;
}

@mixin rgba($type, $color, $percentage, $shade: ligthen) {
  @if $shade==lighten {
    @if $type==color {
      color: solid-lighten($color, $percentage);
      color: rgba($color, $percentage);
    }
    @if $type==bg {
      background-color: solid-lighten($color, $percentage);
      background-color: rgba($color, $percentage);
    }
  }
  @else {
    @if $type==color {
      color: solid-darken($color, $percentage);
      color: rgba($color, $percentage);
    }
    @if $type==bg {
      background-color: solid-darken($color, $percentage);
      background-color: rgba($color, $percentage);
    }
  }
}

@mixin appearance($value: none) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  appearance: $value;
}