.noty_theme__bootstrap-v4.noty_bar {
  margin: 4px 0;
  overflow: hidden;
  position: relative;
  border: 1px solid transparent;
  border-radius: $border-radius;

  .noty_body {
    padding: 13px 15px;

    a {
      color: $white;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    .noty_heading {
      @include bold-text;
      font-size: $h5-font-size;
      margin-bottom: 1rem;
    }
  }

  .noty_buttons {
	  padding: 10px;
  }

  .noty_close_button {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: theme-color('black');
    text-shadow: 0 1px 0 $white;
    filter: alpha(opacity=20);
    opacity: .5;
    background: transparent;
  }

  .noty_close_button:hover {
    background: transparent;
    text-decoration: none;
    cursor: pointer;
    opacity: .75;
  }
}

.noty_theme__bootstrap-v4.noty_type__alert,
.noty_theme__bootstrap-v4.noty_type__notification {
  background-color: $white;
  color: inherit;
}

.noty_theme__bootstrap-v4.noty_type__form {
  background-color: rgba(theme-color('primary-light'), 1);
  border-color: theme-color('primary-medium');

  &.noty_has_timeout.noty_has_progressbar .noty_progressbar {
    opacity: 1;
    background-color: theme-color('primary');
  }

  .noty_body {
    .noty_heading {
      font-size: $h4-font-size;
      margin-bottom: 0;
    }

    form {
      font-size: $font-size-xl;
      margin-top: 1rem;
    }
  }
}

.noty_theme__bootstrap-v4.noty_type__primary {
  background-color: rgba(theme-color('primary'), 1);
  color: $white;
  border-color: theme-color('primary');

  &.noty_has_timeout.noty_has_progressbar .noty_progressbar {
    opacity: 1;
    background-color: theme-color('primary');
  }
}

.noty_theme__bootstrap-v4.noty_type__black {
  background-color: rgba(theme-color('black'), 1);
  color: $white;
  border-color: $gray-800;

  &.noty_has_timeout.noty_has_progressbar .noty_progressbar {
    opacity: 1;
    background-color: $gray-600;
  }
}

.noty_theme__bootstrap-v4.noty_type__warning {
  background-color: theme-color('warning');
  color: $white;
  border-color: theme-color('warning');
}

.noty_theme__bootstrap-v4.noty_type__error {
  background-color: theme-color('danger');
  color: $white;
  border-color: theme-color('danger');
}

.noty_theme__bootstrap-v4.noty_type__info,
.noty_theme__bootstrap-v4.noty_type__information {
  background-color: theme-color('info');
  color: $white;
  border-color: theme-color('info');
}

.noty_theme__bootstrap-v4.noty_type__secondary {
  background-color: theme-color('primary-light');
  border-color: theme-color('primary-medium');
}

.noty_theme__bootstrap-v4.noty_type__success {
  background-color: theme-color('success');
  color: $white;
  border-color: theme-color('success');
}
