// https://loading.io/css/

$color: map-get($theme-colors, 'primary');
$size: 32px;

.lds-hourglass {
  display: inline-block;
  position: relative;
  width: $size;
  height: $size;
}

.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 6px;
  box-sizing: border-box;
  border: ($size / 2.25) solid $color;
  border-color: $color transparent $color transparent;
  animation: lds-hourglass 1.25s infinite;
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}
