// change color of header/navbar 
// based on environment.
// see also: beagle/_top-header

// main app layout
body {
  &.noscroll {
    background-size: cover;
    overflow: hidden;
    cursor: auto;
  }
}

#is_impersonating {
  align-items: center;
  background-color: theme-color('secondary');
  color: $white;
  display: flex;
  height: 50px;
  max-height: 50px;
  padding: 6px;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 1030;

  div {
    @include bold-text;
    align-content: center;
    display: flex;
    margin: 0 auto;

    form {
      margin-left: 1rem;
    }

    .btn {
      border-color: $white;
      color: $white;
    }
  }

  + .be-wrapper .fixed-top {
    margin-top: 50px;
  }

  + .be-wrapper .be-left-sidebar {
    margin-top: 111px;
  }

  + .be-wrapper .page-aside {
    margin-top: 111px;
  }

  + .be-wrapper .be-content {
    margin-top: 50px;
  }
}

.be-collapsible-sidebar-collapsed {
  aside.page-aside {
    @media (min-width: breakpoint('md')) {
      left: 60px;
    }
  }
}

.has-aside {
  @media (min-width: breakpoint('sm')) {
    margin-left: $page-aside-width;
    width: auto;
  }
}

// ensure open right sidebar does not appear OVER
// the username and recent items dropdown in navbar
.open-right-sidebar .be-right-sidebar {
  z-index: $zindex-dropdown - 1;
}

.be-content {
  background-color: $white;
}

.main-content {
  padding: 0;

  .main-content--inner {
    padding: 0 $grid-gutter-width - 20 $grid-gutter-width - 20;
  }

  .main-center {
    padding-bottom: 5rem;
    min-height: 800px;
  }
}

.btn-group {
  > .btn-secondary.disabled {
    pointer-events: none;
    color: $gray-600 !important;
  }
}
