.page-aside {
	// light purple background
  &.page-aside--light {
    background-color: theme-color('primary-light');
  }

  .aside-header {
    .subtitle {
      @include bold-text;
    }
	}
	
	.list-group {
		a {
			background-color: transparent;

			&:hover {
				background-color: theme-color('primary-medium');
				color: theme-color('primary');
			}
		}

		&.no-border {
			a {
				border-left-width: 0;
				border-right-width: 0;
			}
		}
	}
}