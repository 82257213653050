// Give ALL focused buttons a very light blue (info theme color) ring
.btn:focus {
  border-radius: $border-radius;
  box-shadow: 0 0 0 1px map-get($theme-colors, 'info'), 0 0 0 $border-radius rgba(61, 177, 242, .3);
}

// button groups with buttons using .btn-outline-primary class
// are missing the right border color on the last button.
// TODO: Bootstrap bug?
.btn-group {
  > .btn.btn-outline-primary:last-of-type:not(.btn-secondary) {
    border-right-color: theme-color('primary');
  }
}

// there isn't a border radius on XS buttons?!
.btn-xs {
  border-radius: $border-radius;
}

// TODO: determine why this default button state's border
// color isn't being set in bootstrap (or is being overridden?)
.btn.btn-outline-primary {
  border-color: theme-color('primary');
}

.btn.btn-outline-secondary {
  border-color: theme-color('secondary');
}

.btn.btn-outline-success {
  border-color: theme-color('success');
}

.btn.btn-outline-warning {
  border-color: theme-color('warning');
}

.btn.btn-outline-danger {
  border-color: theme-color('danger');
}

.btn.btn-outline-light {
  border-color: theme-color('light');

  &:active,
  &:hover {
    background-color: transparent !important;
    color: $gray-400 !important;
  }
}

.btn.btn-secondary-alt {
  $color: map-get($theme-colors, 'secondary');
  @extend .btn-primary;
  border-color: $color !important;
  background-color: $color !important;

  &:hover {
    background-color: darken($color, 10) !important;
  }
}

/* OVERRIDE PRIMARY BUTTON STYLE — Use seafoam color for buttons */
.btn-primary {
  background-color: theme-color('info') !important;
  border-color: theme-color('info') !important;
  color: $white;

  &:active,
  &:hover {
    background-color: theme-color('info') !important;
    color: $white !important;
  }
}

.btn-outline-primary {
  border-color: theme-color('info') !important;
  color: theme-color('info');

  &:active,
  &:hover {
    background-color: theme-color('info') !important;
    color: $white !important;
  }
}

.show > .btn-outline-primary.dropdown-toggle {
  background-color: theme-color('info') !important;
}

.btn-uikit {
  background-color: #E3E7FD !important; // taffrail-uikit-blue-light
  border-color: #E3E7FD !important; // taffrail-uikit-blue-light
  border-radius: 4px !important;
  color: theme-color('secondary'); // taffrail-uikit-accent

  &:active,
  &:hover {
    background-color: #D8E0FF !important; // taffrail-uikit-blue-light-dark
    border-color: #CFD6DE !important; // taffrail-uikit-border
    color: #0F29B3 !important; // taffrail-uikit-accent-dark
  }

  &:active:focus {
    box-shadow: none !important;
  }
}