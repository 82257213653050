$card-width-max: 95%;
$card-width: map-get($container-max-widths, sm); // 540px
$card-gutter: 16px;

.tf-card {

  &--ontop {
    z-index: $zindex-modal-backdrop + 1;
  }

  &--hidden {
    display: none;
  }

  &--fullscreen {
    $left-right-gutter: (100 - $card-width-max) / 2;
    left: $left-right-gutter;
    width: $card-width-max;

    @media (min-width: breakpoint('md')) {
      width: $card-width-max;
    }
  }

  .modal-header {
    background-color: theme-color('primary');
    color: $white;

    .close {
      color: $white;
      opacity: 1;

      &:hover {
        color: theme-color('tertiary');
      }
    }
  }

  .form-control:focus {
    border-color: theme-color('primary');
  }

  &--entity-type-input_request {
    .modal-header {
      background-color: rule-part-color('inputrequest');
      color: $white;
    }

    .form-control:focus {
      border-color: rule-part-color('inputrequest');
    }
  }

  &--entity-type-advice {
    .modal-header {
      background-color: rule-part-color('advice');
      color: $white;
    }

    .form-control:focus {
      border-color: rule-part-color('advice');
    }
  }

  .form-group-wrapper {
    margin-top: $card-gutter;
    padding: 0 $card-gutter;
  }

  .form-group-buttons {
    display: none;
  }

  &--type-edit_variable {
    .modal-body {
      padding-left: 0;
      padding-right: 0;
    }

    // override bootstrap here in a 2-column modal
    .modal-body .row:last-child .form-group {
      margin-bottom: $form-group-margin-bottom;
    }

    .modal-body .container-fluid {
      padding-left: 19px;
      padding-right: 19px;
    }

    .form-group-wrapper {
      margin-top: $card-gutter;
      padding: 0;
    }

    // CSS reactive to client-side `watchCheckboxFilters`
    .uses-header {
      &[data-selected='none'],
      &[data-selected='some'] {
        .all {
          display: none;
        }
      }
      
      &[data-selected='all'] {
        .none {
          display: none;
        }
      }
    }

    .all-uses-of .card {
      border-width: 1px;
      box-shadow: none;

      mark {
        background-color: lighten($warning, 40%);
        border: 1px solid #E3E3E3;
        border-radius: $border-radius;
        padding-left: .25rem;
        padding-right: .25rem;
        white-space: nowrap;
      }

      .card-header {
        align-items: center;
        border-bottom-width: 1px;
        display: flex;
        justify-content: space-between;
        font-size: $h5-font-size;
        margin: 0;
        padding: $card-spacer-y $card-spacer-x;

        a {
          color: theme-color('primary');
        }

        .btn-primary {
          color: $white;
        }

        // use taffrail-uikit
        .btn-primary.disabled {
          background-color: #E3E7FD !important; // taffrail-uikit-blue-light
          border-color: #CFD6DE !important; // taffrail-uikit-border
          color: theme-color('secondary'); // taffrail-uikit-accent
        }
      }

      .card-body {
        padding: 0;

        li {
          a {
            color: $body-color;
          }
        }

        .is-inactive {
          @include gradient-striped($gray-200);
          background-size: 1.5rem 1.5rem;
        }

        .type-VARIABLE_SET,
        .type-VARIABLE_SET_ASSIGNED {
          @extend code;
        }

        .type-FORMULA {
          cursor: pointer;
        }

        .type-ADVICE {
          // unset the default left margin and bullet
          ul {
            list-style: none;
            padding-left: 0;
          }

          span.link-removed {
            color: theme-color('secondary');
            text-decoration: underline;
          }
        }
      }
    }
  }

  &--type-addAdviceSetCall {
    
    // remove all links
    .rule-tree--branches {
      pointer-events: none;
    }

    .rule-tree--branches__lastcondition_x {
      @extend .progress-bar-striped;
      background-color: theme-color('primary-medium');
      background-size: 1rem 1rem;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      padding: 0 10px 10px;
    }
  }
}

.rules.show.modal-open {
  .selectize-dropdown-content {
    max-height: 300px;
  }
}