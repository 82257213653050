.alert {
  .icon {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
  .message {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    line-height: $line-height-base;
  }

  .icon,
  .close {
    font-size: 1.33rem;
  }
}

// in the Editor section, alerts need spacing beneath the <header>
.advicebuilder-header {
  + .row > div > .alert {
    margin: 1rem 0;
  }
  + .row + .alert { // debug page
    margin: 1rem;
  }
}

.alert.alert-icon {
  align-items: stretch;
  display: flex;
  justify-content: flex-start;
  
  .icon {
    margin-right: .5rem;
  }

  .message {
    margin-top: .25rem;
  }

  p {
    margin-bottom: 0;
  }
}

.alert-link {
  &:hover {
    text-decoration: underline;
  }
}