// tables
// super small - used in formulaBoxHelp currently
.table-xs {
  td {
    padding: 2px;
    font-size: $font-size-sm;
  }
}

.table-responsive {
  display: block;

  @media (min-width: breakpoint('xl')) {
    display: inline-table;
  }
}