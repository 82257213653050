
.page-head {
	padding: $grid-gutter-width - 10 0 0 $grid-gutter-width - 10;

  .page-head-title {
    color: theme-color('black');
    font-size: 1.75rem;
    font-weight: 400;
    margin: 0;
	}

  &.page-head-solo {
    // border-bottom: 1px solid $gray-300;
    padding: 15px 10px 0 10px;
		margin-bottom: 2rem;
	}
}

// big icon preceding page header
.icon-chip {
  background-color: $gray-100;
  border-radius: $border-radius;
  display: inline-block;
  margin-right: 6px;
  padding: 6px;

  i {
    color: theme-color('info') !important; //override text-primary
  }
}
