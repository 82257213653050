// same breakpoints as bootstrap default
// except "XXL" at the bottom
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px, // <- new
  xxxl: 1600px // <- new
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px, // <- new
  xxxl: 1600px // <- new
);

$body-color:                      $gray-700; // default text color
$link-color:                      map-get($theme-colors, 'info');

// alerts
$alert-padding-y:                 .75rem;
$alert-padding-x:                 1.3rem;

$border-radius:                   3px;
$btn-border-radius-base:          $border-radius;

$dropdown-link-active-color:      $white;
$dropdown-link-color:             $link-color;
$dropdown-link-hover-bg:          map-get($theme-colors, 'info');
$dropdown-link-hover-color:       $white;
$dropdown-link-active-bg:         map-get($theme-colors, 'info');

$nav-tabs-link-active-bg:         map-get($theme-colors, 'primary-medium');
$nav-tabs-link-active-border-color: map-get($theme-colors, 'info');

// beagle expand/collapse nav
$arrow-down-code:                 '\f078';
$arrow-up-code:                   '\f077';
$left-sidebar-icon-size:          13px;

$font-letter-spacing:             normal;
$font-size-base:                  1rem * .9;

// bootstrap defaults
// $h1-font-size:                    $font-size-base * 2.5 !default;
// $h2-font-size:                    $font-size-base * 2 !default;
// $h3-font-size:                    $font-size-base * 1.75 !default;
// $h4-font-size:                    $font-size-base * 1.5 !default;
$h5-font-size:                    $font-size-base * 1.1;
$h6-font-size:                    $font-size-base !default;
// tf app overrides
$font-size-h1:                    48px;
$font-size-h2:                    36px;
$font-size-xs:                    ($font-size-base * .7125);
$font-size-xl:                    ($font-size-base * 1.25);
$font-size-content:               ($font-size-base * .65);
$font-weight-thin:                200;
$font-weight-light:               300;
$font-weight-normal:              400;
$font-weight-medium:              500;
$font-weight-bold:                500;
$font-weight-black:               900;

$monospace-font-weight:           $font-weight-normal;

$headings-font-family:            $font-family-sans-serif;
$headings-font-weight:            $font-weight-normal;

$input-focus-border-color:        lighten(map-get($theme-colors, 'black'), 25%);
$input-focus-box-shadow:          0;

$adviceset-banner-height:         60px;

$modal-lg:                        800px;
$modal-md:                        600px;

$tooltip-opacity:                 .9;
$tooltip-bg:                      $gray-800;
$tooltip-border-radius:           $border-radius;

$transition-duration:             .15s;

// lame, but have to re-specify these (copy and paste from BS config)
// so we can re-use them in customizations below
$zindex-dropdown:                 1000;
$zindex-sticky:                   1020;
$zindex-fixed:                    1030;
$zindex-modal-backdrop:           1040;
$zindex-modal:                    1050;
$zindex-popover:                  1060;
$zindex-tooltip:                  1070;
$zindex-noty:                     9999999;
$zindex-selectize:                $zindex-noty + 1;

// dropdowns
$zindex-dropdown-textcomplete:    $zindex-dropdown + 1; // 1001
// loader
$zindex-loading-mask:             $zindex-modal-backdrop - 2;
$zindex-loading-spinner:          $zindex-loading-mask + 1;
// alert
$zindex-alert:                    $zindex-tooltip; // 1070
// cards
$zindex-card-mask:                $zindex-fixed - 1; // 1029
// ui-autocomplete
$zindex-ui-autocomplete:          $zindex-modal + 1;
