// for some dropdowns, we want to use the default down arrow styled in Bootstrap
.dropdown-toggle {
  &.dropdown-toggle--with-arrow {
    &:after {
      display: inline-block !important;
    }
  }
}

.dropdown-menu {
  z-index: $zindex-modal-backdrop;
    
  .dropdown-header {
    margin-top: 0;
  }
  
  > .dropdown-item.disabled {
    pointer-events: none;
    color: $gray-600 !important;
  }
}