.be-right-sidebar {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);

  &.impersonating{
    margin-top: 111px;
  }

  .tab-navigation {
    .nav-link {
      display: flex;
      flex-direction: column;
      padding: 12px 8px !important;

      label {
        cursor: pointer;
        font-size: $font-size-sm;
        font-weight: normal;
        line-height: $line-height-base;
        margin: .25rem 0 0 0;
      }

      &.active {
        label {
          color: theme-color('primary');
        }
      }
    }
  }

  .tab-settings {
    .be-scroller-settings {
      padding: 10px 5px;
    }
  }

  .alert {
    margin-bottom: .5rem;
    padding: 10px 8px;
  }

  .group-header {
    margin: .25rem 0;

    a.btn-collapse {
      color: theme-color('taffrail-uikit-text-hint');
      font-size: $font-size-sm;

      &::before {
        @include fa-icon-solid($fa-var-chevron-right);
      }

      &[aria-expanded="true"] {
        &::before {
          @include fa-icon-solid($fa-var-chevron-up);
        }
      }
    }
  }

  .all-rules-in-adviceset {	
    list-style-type: none;

    .list-group-item {
      padding: 10px 8px;

      &:first-child {
        .rule-link {
          margin-right: 0; // no space needed for drag handle on 1st primary rule, it cannot be sorted
        }
      }
    }

    .rule-link {
      margin-right: 0;
      width: 100%;
    }

    .rule-status-icon {
      font-size: 1rem;
      margin-right: 2px;
    }

    .rule-sort-handle-icon {
      display: none;
      margin-right: 2px;
    }
    
    .list-group-item:not([data-is-sortable=false]) {
      .icons:hover {	
        .rule-status-icon {
          display: none;
        }

        .rule-sort-handle-icon {
          display: inline-block;
          cursor: grab;
        }
      }
    }
    
    .badge {
      background-color: transparent;
      border: 0 none;
      color: theme-color('taffrail-uikit-text-hint');
      font-size: $font-size-xs;
      padding: 0 2px 0 0;
    }

    .list-group-item.active {
      background-color: theme-color('taffrail-uikit-accent-light');
      border-color: $list-group-border-color;
      border-top-width: 0;

      &:first-child {
        border-top-width: 1px;
      }

      .icon {	
        color: theme-color('taffrail-uikit-accent') !important;	
        
        &.text-warning {
          color: theme-color('warning') !important;
        }
      }
    }
    
    // rules table
    &.ui-sortable {
      .sortable-placeholder {
        background-color: theme-color('taffrail-uikit-seafoam-light');
        border: 1px solid theme-color('taffrail-uikit-seafoam-text');
        border-style: dashed solid;
        border-radius: $border-radius;
      }
    
      .handle.ui-sortable-handle {
        cursor: grab;
      }
    
      .ui-sortable-helper {
        border-radius: $border-radius;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, .5);
        opacity: 0.8;
      }
    }
  }
  
  .list-group-item,	
  .list-group-item a {	
    color: $body-color;	
  }

  .divider {
    @include bold-text;
    color: $gray-500;
    font-size: $font-size-sm;
    margin-bottom: .75rem;
    text-transform: uppercase;
  }
  
  .tab-pane {
    .dropzone {
      position: unset;
    }

    .todo-container {
      height: 100%;
      width: 100%;
      display: table;
      table-layout: fixed;
      position: absolute;
      left: 0;
      transition: left 0.3s ease;
    }

    .content {
      padding: 10px 5px;
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
    }

    .dropdown-toggle {
      color: theme-color('primary');
      font-size: $font-size-base;
    }

    .dropleft .dropdown-toggle::before {
      all: unset;
    }
  }

  form.supplement-url {
    .form-group {
      margin-bottom: .25rem;
    }
  }

  .supplements-urls .card {
    border-width: 1px;
    padding-left: $card-spacer-x / 2;
    padding-right: $card-spacer-x / 2;

    h5 {
      margin: 0;
      a {
        color: theme-color('primary');
      }
    }

    .card-body {
      padding-bottom: .75rem;
      padding-top: .75rem;
    }

    .card-footer {
      padding-left: 0;
    }
  }

  .dz-attach-aside {
    font-size: $font-size-sm;

    .alert {
      color: $gray-600;
      line-height: $line-height-base;
    }
  }
}