// Taffrail - Advice Builder web app
//  
// This is the "entry point" SASS file for all styles on the app.
// The app uses Bootstrap 4.x and the Beagle template.
// https://themes.getbootstrap.com/product/beagle-responsive-admin-template/
//
// NOTICE **********
// The order of @import statements for compilation is important in this file
// The vendor imports are at the very bottom
// Beagle is imported where it is because it includes variables to override.
//

// App mixins & functions not in Bootstrap
@import 'mixins';
@import 'functions';

// App variable overrides. 
// Many are direct overrides of what's in Bootstrap. 
// Others are custom for this app.
// 
// NOTICE **********
// A variable you might be looking for could be in:
//    src/beagle/src/sass/config/_variables.scss
// NOTICE **********

// font awesome config
$icons-font: "Font Awesome 6 Pro"; // for beagle

@import 'app/colors';
@import 'app/fonts';
@import 'app/icons';

@import 'tf_variables';

// import BEAGLE
@import '../beagle/src/sass/beagle';

// vendor common
@import 'vendor/noty/noty';
@import 'vendor/selectize.bootstrap3';

// common
@import 'app/loaders';
@import 'app/loaders-hourglass';
@import 'app/type';
@import 'app/buttons';
@import 'app/accordion';
@import 'app/dropdowns';
@import 'app/cards';
@import 'app/alerts';
@import 'app/modals';
@import 'app/tables';
@import 'app/tooltips';
@import 'app/utilities';
@import 'app/page-headers';
@import 'app/forms';
@import 'app/auth';

// layout
@import 'app/navbar';
@import 'app/main';
@import 'app/aside';
@import 'app/right-sidebar';

// modules
@import 'modules/activity';
@import 'modules/formulabox';
@import 'modules/user-timeline';
@import 'modules/nav-autocomplete';
@import 'modules/tablesorter';
@import 'cards/card';
@import 'cards/rule-visualize';

// pages
@import 'pages/admin/apiKeys';
@import 'pages/rulesets/activity';
@import 'pages/rulesets/explore';
@import 'pages/rulesets/screener';
@import 'pages/rulesets/embed';
@import 'pages/rulesets/scenarios';
// rule editor
@import 'pages/editor/header';
@import 'pages/editor/show';
@import 'pages/editor/show_editor';
@import 'pages/rules/detail';
@import 'pages/rules/debug';