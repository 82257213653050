@import "../../../../node_modules/placeholder-loading/src/scss/placeholder-loading";

// answers
.answers {
  $li-left-spacing: 1.5rem;

  list-style: none;
  padding: 0;
  position: relative;

  li {
    border-top: 1px solid $gray-400;
    display: inline-block;
    margin-bottom: .5rem;
    opacity: 0;
    padding-left: $li-left-spacing;
    padding-top: $li-left-spacing/2;
    position: absolute;
    width: 100%;

    .answer--value {
      font-weight: bold;
    }

    &.answer--INPUT_REQUEST,
    &.answer--ADVICE {
      &:before {
        @extend %fa-icon;
        @extend .far;
        color: theme-color('primary');
        display: inline-block;
        margin-left: $li-left-spacing * -1; /* same as padding-left set on li */
        padding-right: 10px;
        width: $li-left-spacing; /* same as padding-left set on li */
      }
    }

    &.answer--INPUT_REQUEST {
      &:before {
        content: fa-content($fa-var-question);
      }
    }
    &.answer--ADVICE {
      &:before {
        content: fa-content($fa-var-comments);
      }
    }
  }
}

.debug-tree {
  position: relative;

  section {
    margin-bottom: 5px;

    &.rule-node {
      align-items: stretch;
      background-color: $gray-100;
      border-color: $gray-400;
      border-radius: $border-radius;
      border-style: solid;
      border-width: 1px;
      color: $body-color;
      display: flex;
      justify-content: space-between;
      min-height: 40px;
      width: 100%;

      &:not(.rule-node--active):hover,
      &:not(.rule-node--active):hover + ul .rule-node {
        background-color: theme-color('primary-light');
        border-color: theme-color('primary-medium');
      }
    }

    .rule-node__handle {
      align-items: center;
      color: $body-color;
      display: flex;
    }

    .rule-node__type-icon {
      display: flex;
      min-height: 40px - (1px * 2);
      width: 40px - (1px * 2);

      i {
        align-items: center;
        display: flex;
        font-size: $font-size-xl;
        font-style: normal;
        justify-content: center;
        width: 100%;
      }
    }

    .rule-node__name {
      align-items: center;
      display: flex;
      flex: 1;
      line-height: 1;
      overflow: hidden; // hidden b/c Windows 
      padding: 3px 0;
      width: 100%;
  
      .formula {
        font-family: $font-family-monospace;
        font-size: 85%;
        font-weight: $monospace-font-weight;
        line-height: 1.4;
      }

      div ~ code {
        display: block;
        font-size: 10px;
        margin-top: 3px;
      }
    }

    .rule-node__name-ruleset {
      font-size: 80%;
      margin-top: 3px;
    }

    &.rule-node--CONDITION_ALWAYS,
    &.rule-node--RULE_CALL {
      min-height: 20px;

      .rule-node__type-icon {
        min-height: 20px - (1px * 2);
      }

      * {
        font-size: 90%;
        color: $gray-600 !important;
      }
    }

  }
}