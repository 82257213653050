$white:                           #fff !default;
$gray-100:                        #f8f9fa !default;
$gray-200:                        #e9ecef !default;
$gray-300:                        #dee2e6 !default;
$gray-400:                        #ced4da !default;
$gray-500:                        #adb5bd !default;
$gray-600:                        #868e96 !default;
$gray-700:                        #495057 !default;
$gray-800:                        #343a40 !default;
$gray-900:                        #212529 !default;
$black:                           #000 !default;

$theme-colors: (
  "primary": #1C2145,             // classic navy
  "primary-dark": #12142d,        // classic navy
  "primary-light": lighten(#1C2145, 75%),// classic navy
  "primary-medium": lighten(#1C2145, 70%),// classic navy
  "secondary": #3956EF,           // digital blue
  "tertiary": #67DFBA,            // seafoam
  "warning": #EC9E38,             // brass
  "danger": #EC2758,              //
  "info": #3956EF,                // digital blue, dark
  "black": #21232B,               // space black
  // "success": #00B828,             // positive green
  "success": #00d8a0,             // seafoam
  "success-light": #E6FAF4,        // seafoam-light
  "success-dark": #15654C,        // dark green
  "light": #fff,
  "grey": #f1f1f1,
  "dark": #1C2145,                // classic navy
);

$primary-light:                   map-get($theme-colors, 'primary-light');
$primary-medium:                  map-get($theme-colors, 'primary-medium');

$taffrail-colors: (
  "taffrail-primary": #1C2145,
  "taffrail-secondary": #3956EF,
  "taffrail-brass": #EC9E38,
  "taffrail-seafoam": #67DFBA,
  "taffrail-gray-100": #EFF0F1,
  "taffrail-gray-200": #D3D7D9,
  "taffrail-gray-300": #B8BEC1,
  "taffrail-gray-400": #818C92,
  "taffrail-gray-500": #50595D,
  "taffrail-gray-600": #212426,
  "taffrail-body-text": #474B6B,
  "taffrail-uikit-text-hint": #1C214599,
  "taffrail-uikit-accent": #3956EF,
  "taffrail-uikit-accent-light": #3956EF1A,
  "taffrail-uikit-accent-dark": #0F29B3,
  "taffrail-uikit-blue": #3956EF,
  "taffrail-uikit-blue-light": #E3E7FD,
  "taffrail-uikit-blue-dark": #0F29B3,
  // same as `uikit-accent-dark`
  "taffrail-uikit-brass": #EC9E38,
  "taffrail-uikit-brass-text": #945605,
  "taffrail-uikit-brass-light": #FBEDDA,
  "taffrail-uikit-border": #CFD6DE,
  "taffrail-uikit-border-light": #EAEDF0,
  "taffrail-uikit-border-dark": #B7C1CD,
  "taffrail-uikit-surface": #F6F7F9,
  "taffrail-uikit-surface1": #F0F2F4,
  "taffrail-uikit-surface2": #E7EBEE,
  "taffrail-uikit-surface3": #DEE3E8,
  "taffrail-uikit-surface4": #D5DBE2,
  "taffrail-uikit-dark-text-hint": #c5c6ce99,
  "taffrail-uikit-dark-surface": #212529,
  "taffrail-uikit-dark-surface1": #1b1f22,
  "taffrail-uikit-dark-border": #495057,
  "taffrail-uikit-seafoam": #67DFBA,
  "taffrail-uikit-seafoam-light": #E6FAF4,
  "taffrail-uikit-seafoam-text": #15654C,
  "taffrail-uikit-spring-green": #157145,
  "taffrail-uikit-poppy": #DF2935,
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $taffrail-colors);

// rules and rule parts
$rule-part-colors: (
  'topic': map-get($theme-colors, 'tertiary'),
  'rule_call': map-get($theme-colors, 'tertiary'),
  'condition': map-get($theme-colors, 'secondary'),
  'outcome': map-get($theme-colors, 'primary'),
  'variable_set': map-get($theme-colors, 'primary'),
  'input_request': map-get($theme-colors, 'info'),
  'advice': map-get($theme-colors, 'warning')
);

@each $part, $color in $rule-part-colors {
  .rule-part-text-#{$part} {
    color: $color;
  }
  .rule-part-fill-#{$part} {
    fill: $color;
  }
}