:root {
  --aa-detached-media-query: (max-width:680px);
  --aa-detached-modal-media-query: (min-width:680px);
  --aa-detached-modal-max-width: 680px;
  --aa-detached-modal-max-height: 500px;
}

.aa-Autocomplete {
  width: 100%;

  :focus-visible {
    outline: none;
  }

  .aa-Input {
    width: 100%;
  }

  .aa-Form {
    .aa-InputWrapperPrefix,
    .aa-InputWrapperSuffix {
      display: none;
    }

    .aa-InputWrapper {
      order: 3;
      position: relative;
      width: 100%
    }


  }
}

.aa-Panel {
  background-color: $white;
  border: 1px solid $gray-400;
  border-top: 0 none;
  width: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 9999;

  .aa-Source {
    .aa-SourceHeader {
      align-items: center;
      display: flex;
      font-size: $font-size-sm;
      justify-content: flex-start;
      padding: $nav-link-padding-y $nav-link-padding-x;

      label {
        font-size: $font-size-sm;
      }

      .form-check,
      .form-check-inline {
        margin: 0;
      }

      .form-check-inline {
        margin-left: $nav-link-padding-x;
      }
    }

    .aa-List,
    .aa-SourceNoResults {
      .aa-Item,
      .aa-noresult {
        cursor: pointer;
        padding: $nav-link-padding-y $nav-link-padding-x;

        header {
          display: flex;
          line-height: $line-height-sm;
          margin: 0;

          // sass-lint:disable nesting-depth
          .i {
            margin-right: 3px;
            width: 18px;
          }
        }

        .text-sm {
          font-size: 75%;
          line-height: $line-height-sm;
          margin-left: 20px;
        }

        &.aa-cursor {
          background-color: theme-color('primary-light');
        }

        em {
          @include bold-text;
        }
      }

      .aa-Item {
        &[aria-selected=true] {
          background-color: $primary-light;
        }
      }

      .aa-noresult {
        cursor: default;
      }
    }

    .aa-List {
      list-style: none;
      margin: 0;
      padding: 0;
      position: relative;
    }

    .aa-SourceFooter {
      footer {
        font-size: $font-size-sm;
        padding: $nav-link-padding-y $nav-link-padding-x;
      }
    }

  }
  
}