.loading-mask,
.card-mask {
  background: #fff none repeat scroll 0%;
  border: 0 none;
  opacity: .6;
  position: absolute;
  z-index: $zindex-loading-mask;
}

.loading-mask--cover-clear {
  background-color: transparent;
  opacity: 1;
}

.loading-spinner {
  position: absolute;
  z-index: $zindex-loading-spinner;
}

#change-page-spinner {
  align-items: center;
  display: none;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: $zindex-loading-spinner;

  &.show {
    display: flex;
  }

  .loading-mask {
    height: 100%;
    width: 100%;
  }

  .spinner {
    @extend .lds-hourglass;
    z-index: $zindex-loading-spinner + 1;
  }
}

.cursor-wait {
  cursor: wait;

  .ace_scroller,
  label,
  input,
  select,
  textarea {
    cursor: wait;
  }
}