.activity {

  > .card-header {
    align-items: center;
    display: flex;

    select {
      margin: 0 10px;
      height: 2.5rem;
      width: auto !important;
    }
  }

  ul.activity-items {
    list-style: none;
    padding-left: 0;

    div.user-initials {
      background: $gray-200;
      height: 28px;
      width: 28px;

      margin: 0 0 4px 4px;
      border-radius: 25em;

      color: $body-color;
      display: inline-flex;
      overflow: visible;
      position: relative;
      vertical-align: middle;
      
      > span {
        line-height: 28px;
        font-size: 11px;
        text-align: center;
        width: 100%;
      }
    }
  }
}