
table.tablesorter {

	/* style header */
	.tablesorter-header:not(.sorter-false) {
    cursor: pointer;
    outline: none;

    .tablesorter-header-inner {
      padding-right: 20px;
      position: relative;

      &:before {
        @extend %fa-icon;
        @extend .fas;
        content: fa-content($fa-var-sort);
        position: absolute;
        top: 4px;
        right: 5px;
      }
    }
    
    &.tablesorter-headerAsc {
      .tablesorter-header-inner:before {
        content: fa-content($fa-var-sort-up);
      }
    }

    &.tablesorter-headerDesc {
      .tablesorter-header-inner:before {
        content: fa-content($fa-var-sort-down);
      }
    }
	}
}