.CodeMirror-hints {
  z-index: $zindex-tooltip + 1;
}

.formulabox {

  &.disabled,
  &.disabled + .CodeMirror.cm-s-advicebuilder {
    // make formulaboxes look disabled, if they are.
    background-color: $input-disabled-bg !important;
  }

  + .CodeMirror.cm-s-advicebuilder {
    // formulaboxes should mostly look like .form-controls
    @extend .form-control;
    // make it look like code
    font-family: $font-family-monospace;
    font-size: $font-size-base * .9;
    font-weight: $font-weight-normal;
    // setting height:auto allows CodeMirror to auto expand height based on content
    height: auto;
    // min-height: 43px;
    padding-bottom: 6px;
    padding-top: 6px;

    // match focused/active state with other input boxes
    &.CodeMirror-focused {
      border-color: $input-focus-border-color;
    }

    // placeholder text should be grey
    pre.CodeMirror-placeholder { color: $gray-600; }

    // theme colors kinda-sorta based on https://github.com/thenikso/github-plus-theme

    .cm-keyword { color: #032f62; } // keywords (function, var, Math.x(), .includes, etc)
    .cm-variable { color: #000; } // JS variable ("Married")
    .cm-number { color: #22863a; } // numbers
    .cm-atom { color: #6f42c1; } // true, false, undefined, null
    .cm-operator { color: #d73a49; } // all operators

    // red squiggly underline
    .cm-trailingspace {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAACCAYAAAB/qH1jAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3QUXCToH00Y1UgAAACFJREFUCNdjPMDBUc/AwNDAAAFMTAwMDA0OP34wQgX/AQBYgwYEx4f9lQAAAABJRU5ErkJggg==);
      background-position: bottom left;
      background-repeat: repeat-x;
    }
    
    // variable and formula entities should always look like code
    .cm-entity-variable,
    .cm-entity-formula {
      font-family: $font-family-monospace;
      font-size: $font-size-base * .9;
      font-weight: $font-weight-medium;
    }

    // highlight variable and formula entities
    .cm-entity-variable { color: theme-color('taffrail-uikit-blue-dark'); }
    .cm-entity-formula { color: theme-color('taffrail-uikit-blue-dark'); }

    // define matching bracket colors last so this overrides variable and formula colors
    span.CodeMirror-matchingbracket { 
      background-color: #f1f8ff; 
      border: 1px solid #c8e1ff; 
      color: #0b0 !important;
    }
    span.CodeMirror-nonmatchingbracket { 
      background-color: #FEE5E7; 
      border: 1px solid #c8e1ff; 
      color: #a22 !important; 
      font-weight: bold;
    }
  }

  &.form-control--field-visibility-public {
    + .CodeMirror.cm-s-advicebuilder {
      border: 1px dotted theme-color('secondary');

      // match focused/active state with other input boxes
      &.CodeMirror-focused {
        border-color: theme-color('secondary');
        border-style: solid;
      }
    }
  }

  // if a .formulabox is setup to allow variables but look like text (Advice, User Questions)
  // change the font-family back to serif
  &.formulabox--prose + .CodeMirror.cm-s-advicebuilder {
    font-family: $font-family-sans-serif;
    font-size: $font-size-base;
  }
}

.CodeMirror-hints.advicebuilder {
  font-family: $font-family-monospace;
  font-size: $font-size-base * .9;
  font-weight: $monospace-font-weight;

  li.CodeMirror-hint {
    color: theme-color('black');
    padding: 2px 4px;
  }

  li.CodeMirror-hint-active {
    background-color: theme-color('primary-light');
    color: theme-color('primary');
  }
}
