$border-width: 1px;
$node-box-size: 64px;
$node-box-size-height: 40px;
$node-width: $node-box-size * 5.25;
$boxspacing: $border-width;
$label-font-size: $font-size-sm; // slightly larger because the typeface is different
$content-font-size: $font-size-content;
$cell-height: ($node-box-size/ 2) - (($border-width * 3) / 2); // height of box less border & margin for middle line
$outcome-group-spacer: 12px;
$btn-margin: 8px;

.rule-detail {

  margin-top: 20px;

  // each node is a <section>
  .rule-node {
    align-items: stretch;
    border-radius: $border-radius;
    border-style: solid;
    border-width: $border-width;
    display: flex;
    min-height: $node-box-size-height;
    width: 100%;

    @media (min-width: breakpoint('lg')) {
      width: $node-width;
    }

    &.active,
    &:hover {
      background-color: $gray-100;
    }

    hr {
      margin: $border-width 0;
      border-top-width: $border-width;
    }

    // specific colors
    &.rule-node--topic,
    &.rule-node--rulecall {
      border-color: rule-part-color('topic');
      color: rule-part-color('topic');

      > figure > a,
      > figure > a i {
        color: rule-part-color('topic');
      }
      
      nav {
        background-color: rule-part-color('topic');
      }

      hr {
        border-top-color: rule-part-color('topic');
      }

      footer .content {
        color: rule-part-color('topic');
      }
    }

    &.rule-node--condition {
      border-color: rule-part-color('condition');
      color: rule-part-color('condition');

      > figure > a {
        color: rule-part-color('condition');
      }

      nav {
        background-color: rule-part-color('condition');
      }

      hr {
        border-top-color: rule-part-color('condition');
      }

      footer .content {
        color: rule-part-color('condition');
      }
    }

    &.rule-node--outcome,
    &.rule-node--variable_set {
       border-color: rule-part-color('outcome');
      color: rule-part-color('outcome');

      > figure > a {
        color: rule-part-color('outcome');
      }

      nav {
        background-color: rule-part-color('outcome');
      }

      header {
        padding-right: 30px;
      }

      hr {
        border-top-color: rule-part-color('outcome');

      }

      footer .content {
        color: rule-part-color('outcome');
      }
    }

    &.rule-node--rulecall {
      header {
        padding-right: 30px;
      }

      > section.rule-node__name {
        cursor: auto; 
      }
    }

    &.rule-node--advice {
      border-color: rule-part-color('advice');
      color: rule-part-color('advice');

      > figure > a {
        color: rule-part-color('advice');
      }

      nav {
        background-color: rule-part-color('advice');
      }

      hr {
        border-top-color: rule-part-color('advice');
      }

      footer .content {
        color: rule-part-color('advice');
      }
    }

    &.rule-node--inputrequest {
      border-color: rule-part-color('inputrequest');
      color: rule-part-color('inputrequest');
    }

    figure {
      align-content: center;
      align-items: stretch;
      border-radius: $border-radius;
      border-bottom-right-radius: 0;
      border-width: 0;
      border-style: solid;
      border-right-width: $border-width;
      display: flex;
      min-height: $node-box-size-height - ($border-width * 2);
      margin: 0;
      width: $node-box-size-height - ($border-width * 2);

      > span,
      > a,
      > i {
        align-items: center;
        display: flex;
        font-size: $font-size-lg;
        justify-content: center;
        text-decoration: none;
        width: 100%;
      }
    }

    .rule-node__name {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex: 1;
      line-height: 1;
      padding-left: $node-box-spacing;
      padding-right: $node-box-spacing;
      width: 100%;

      &:not(.rule-node__name--form){
        @include bold-text;
      }

      a {
        text-decoration: none;
      }

      > a {
        width: 100%;
      }

      small {
        font-size: $rule-reserved-name-font-size;
      }
    }

    &.rule-node--empty {
      border: none;
      box-shadow: none;
    }
  }

  // horizontal spacers
  .row {
    margin-left: 0;
    margin-right: 0;
    // the columns on this page are unique 
    // each column needs to "touch" with zero gutter 
    // so lines are connected
    // the far left and far right columns need page gutters on larger resolutions

    .col-md-4 {
      display: flex;
      padding-left: 0;
      padding-right: 0;
      $cols: 4;
      $gutter: 0;
      @extend .no-gutters;
      @include make-col($cols);
    }

    &.row--spacer,
    &.row--spacer--outcome-group {
      .rule-node {
        border: none;
        box-shadow: none;
        
        &:hover {
          background-color: transparent;
        }
      }
    }
    
    &.row--spacer {
      height: $node-box-size-height;
    }

    &.row--spacer--outcome-group {
      height: $outcome-group-spacer;

      .rule-node {
        height: $outcome-group-spacer;

        .hasborder,
        .noborder {
          height: $outcome-group-spacer;
        }
      }
    }

    .rule-node--empty {
      &:hover {
        background-color: transparent;
      }

      > .hasborder {
        height: $node-box-size-height;
        border-left: $border-width solid;
        margin-left: $node-box-size / 2;
        width: 50%;
      }
    }

    .rule-node--topic {
      > .hasborder {
        border-left: 0 none;
      }
    }

    .rule-node--condition {
      > .hasborder {
        border-left-color: rule-part-color('condition');
      }
    }

    .rule-node--outcome,
    .rule-node--variable_set {
      > .hasborder {
        border-left-color: rule-part-color('outcome');
      }
    }

    .connector{
      align-items: center;
      display: flex;
      flex: 1;
      justify-content: center;

      &.connector--topic hr {
        border-top: $border-width solid rule-part-color('topic');
      }

      &.connector--condition hr {
        border-top: $border-width solid rule-part-color('condition');
      }
      
      hr {
        height: $border-width;
        margin: 0;
        width: 100%;
      }
    }
  }
}

#rule-node-card {
  display: none;
  @include rgba(bg, theme-color('black'), .7);
  bottom: 0;
  color: #fff;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;

  .card-editor {
    $textarea-height: 48px;

    position: absolute;
    z-index: 10000;

    .rule-node {
      height: auto;
      display: block;

      &:hover {
        background: transparent;
      }

      
      nav {
        border-radius: $border-radius;
        display: flex;
        height: ($textarea-height * 2) + ($border-width * 3);
        left: 0;
        position: absolute;
        top: 0;
        width: $node-box-size;
        
        > a,
        > span {
          border: 0 none;
          color: #fff;
          display: inline-block;
          margin: auto;

          .rule-node--sequence {
            font-family: $font-family-sans-serif-semibold;
          }

          .mdi {
            display: none;
          }
        }

        > a {
          cursor: text;
          text-decoration: none;
        }

        &:hover {
          > a {
            .rule-node--sequence {
              display: inline-block;
            }
          }
        }
      }

      header,
      footer {
        height: $textarea-height;

        .form-control {
          box-shadow: none;
          height: $textarea-height;
        }
      }
 
      // above the line   
      header {
        font-size: $label-font-size;
        height: $cell-height;
        line-height: 1;
        padding-left: $node-box-size + $border-width;
        width: 100%;
        display: table;
        padding-right: 0;

        .form-control {
          font-size: $font-size-xs;
        }

        .name {
          height: $cell-height;
          overflow: hidden;
          width: 100%;
          display: table-cell;
          vertical-align: middle;
        }
      }

      // below the line
      footer {
        font-size: $content-font-size;
        height: $cell-height;
        line-height: $cell-height;
        padding-left: $node-box-size + $border-width;
        width: 100%;

        .content {
          font-family: $font-family-monospace;
          font-weight: $monospace-font-weight;
          height: $cell-height;
          overflow: hidden;
          width: 100%;
        }

        .form-control {
          font-size: $content-font-size;
        }
      }

      &.rule-node--topic {
        footer {
          .form-control {
            color: rule-part-color('topic');
          }
        }
      }

      &.rule-node--outcome,
      &.rule-node--rulecall {
        header {
          padding-right: 0;
        }
      }
    }

    .buttons {
      margin: $border-width 0 0 $node-box-size;
    }

    .editor-entity-modifier {
      $form-width: 235px;
      background-color: #fff;
      border-radius: $border-radius;
      display: none;
      left: -$form-width - $btn-margin;
      margin-right: $btn-margin;
      position: absolute;
      top: 0px;
      width: $form-width;
      
      // outcome editor on the far left
      &.editor-entity-modifier--outcome {
        left: -$form-width * 2 + $btn-margin;
        margin-right: 0;
        width: $form-width;
      }

      h5 {
        color: $body-color;
        margin-top: 0;
      }

      .closex {
        position: absolute;
        top: 0;
        font-size: $font-size-lg;
        text-decoration: none;
        color: $gray-200;
        @include bold-text();
      }

      .input-sm,
      .ui-menu {
        font-size: $font-size-sm;
      }
    }
  }
}