/*------------------------------------------------------------------
  [Tabs]
*/
.nav-tabs {
	background-color: theme-color("light");
	border-bottom: 2px solid darken(theme-color("light"), 8%);

	> li.nav-item {
		margin-bottom: -2px;
		margin-right: 2px;

		a.nav-link {
			padding: 11px 20px;
			margin-right: 0;  
			min-width: 60px;
    	text-align: center;
    	border-radius: 0;
    	color: $body-color;
    	border-width: 0;

    	.icon {
    		font-size: 1.538rem;
    		vertical-align: middle;
    		margin: 0 6px;
    		line-height: 17px;
    	}

    	&:hover {
    		background: transparent;
				color: theme-color("primary");
    	}

    	&:active {
    		background-color: transparent;    		
    	}

    	&.active {
    		background: transparent;
    		border-bottom: 2px solid $nav-tabs-link-active-border-color;

    		.icon {
    			color: #555;		
    		}    		

    		&:hover, &:focus {
    			color: $body-color;
    		}
		}
		
		&.disabled{
			color: $gray-600;
		}
		}
	}
}

.nav-tabs-classic {
	background-color: transparent;
	border-bottom: 0;

	> li.nav-item {
		margin-bottom: 0;
		margin-right: 0;

			a.nav-link {
				border-bottom: 0;

				&.active {
					background-color: $white;
					border-bottom: 0;
					border-radius: 3px 3px 0 0;

					.tab-left & {
						border-radius: 3px 0 0 3px;
					}
				}
			}
	}
}

//Tab Left and Tab Rgiht
.tab-left, .tab-right {

	.nav-tabs {
		float: left;
		flex-direction: column;

		> li.nav-item {
			float:none;
			margin-right: 0;
			margin-bottom: 0;

			> a.nav-link {
				text-align: left;
				min-width: 38px;
				padding: 14px 12px;
			}
		}
	}

	.tab-content {
		overflow: auto;
	}
}

.tab-content {
	background: theme-color("light");
	padding: 20px;
	margin-bottom: 40px;
	border-radius: 0 0 3px 3px;

	.tab-pane {
		
		//First heading margin-top fix
		h1, h2, h3, h4, h5, h6 {

			&:first-child {
				margin-top: 5px;
			}
		}
	}

	.card {
		box-shadow: unset;

		.card-header {
			margin-right: 0px;
			margin-left: 0px;
		}
		
		.card-body {
			padding-right: 0px;
			padding-left: 0px;
		}
	}
}

// Navs Color States

.nav-tabs-primary{
	@include tabs-color(theme-color("primary"));
}
.nav-tabs-success{
	@include tabs-color(theme-color("success"));
}
.nav-tabs-warning{
	@include tabs-color(theme-color("warning"));
}
.nav-tabs-danger{
	@include tabs-color(theme-color("danger"));
}