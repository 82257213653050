.library.bulkeditor,
.library.search {

  .aside-header {
    margin-bottom: .5rem;

    &:not(:first-child) {
      margin-top: 1.5rem;
    }
  }

  // filter overrides
  [class^='ais-'] {
    font-size: $font-size-base;
  }

  label.ais-RefinementList-label,
  label.ais-NumericMenu-label,
  label.ais-ToggleRefinement-label {
    align-items: center;
    display: flex;
    margin-bottom: .5rem;

    input {
      margin-right: .25rem;
    }

    span.ais-RefinementList-count {
      border-radius: $border-radius;
      font-size: $font-size-sm;
      margin-left: .25rem;
      padding: 0 .25rem;
    }
  }

  .ais-RefinementList-showMore {
    @extend .btn;
    @extend .btn-sm;
    @extend .btn-secondary;
  }

  .ais-RefinementList-showMore--disabled {
    @extend .disabled;
  }

  .ais-RefinementList-count {
    background-color: theme-color('primary-medium');
  }

  // need to reset a bunch of .form-control-lg stuff
  .ais-SearchBox-input.form-control-lg {
    border-color: $input-border-color;
    border-radius: $border-radius;
    font-size: $input-font-size-lg;
    font-weight: $input-font-weight;
    height: $input-height-lg;
    line-height: $input-line-height-lg;
    padding: $input-padding-y-lg $input-padding-x-lg $input-padding-y-lg 1.7rem;

    &::placeholder {
      color: $input-placeholder-color;
    }

    &:focus {
      border-color: $input-focus-border-color;
    }
  }

  .ais-Stats-text {
    color: $body-color;
    font-size: $input-font-size;
  }

  .ais-HitsPerPage-select,
  .ais-SortBy-select {
    border-color: $input-border-color;
    border-radius: $border-radius;
    font-size: $input-font-size;
    font-weight: $input-font-weight;
    height: $input-height-sm;
    padding-bottom: 1px;
    padding-top: 1px;
  }

  .ais-RefinementList-labelText,
  .ais-ToggleRefinement-labelText,
  .ais-NumericMenu-labelText {
    color: $body-color;
  }

  .ais-CurrentRefinements-item {
    background-color: theme-color('primary-light');
    border: 1px solid theme-color('primary-medium');
    border-radius: $border-radius;

    .ais-CurrentRefinements-label,
    .ais-CurrentRefinements-categoryLabel {
      color: theme-color('primary');
    }

    .ais-CurrentRefinements-categoryLabel {
      @include bold-text;
    }

    .ais-CurrentRefinements-delete {
      color: theme-color('secondary');

      &:hover {
        color: darken(theme-color('secondary'), 10%);
      }
    }
  }

  .ais-ClearRefinements-button {
    @extend .btn;
    @extend .btn-secondary;
    @extend .text-nowrap;
  }

  .ais-Pagination-link {
    border-color: $input-border-color;
    color: theme-color('info');
  }

  .ais-Pagination-item--selected {
    .ais-Pagination-link {
      background-color: theme-color('primary');
      border-color: theme-color('primary');
      color: $white;
    }
  }

  // Hits
  ul {
    // each "card" is a single hit
    .card {
      @extend .card-border-color;

      .card-header {
        // ais-Highlight-highlighted
        // search string highlighting...
        mark {
          background-color: theme-color('warning');
          font-size: unset;
        }

        .title-text {
          margin-bottom: .25rem;
          // max-width: 400px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          a {
            color: theme-color('primary');
            display: inline-block;
            font-weight: 400;
            margin-left: .25rem;
          }

          i {
            color: theme-color('primary');
          }

          + .text-sm {
            white-space: nowrap;
          }
        }
      }

      &:hover {
        @extend .card-border-color-primary;
        border-color: theme-color('primary');

        .card-header {
          @extend .card-header-contrast;
        }
      }

      footer {
        @extend .text-sm;
        @extend .text-muted;
      }
    }
  }
}

.library.bulkeditor {
  #hits {
    overflow: hidden;
    position: relative;
    white-space: nowrap;
  }

  #current-columns {
    .form-check {
      margin-right: .35rem;

      label {
        font-size: $font-size-sm;
      }
    }
  }

  table {
    // https://mariusmateoc.com/blog/automatic-serial-number-row-in-html-table/
    counter-reset: serial-number;  // Set the serial number counter to 0

    th {
      white-space: nowrap;
    }

    tr td {
      padding: 4px 6px;
      vertical-align: top;
    }

    td.stack {
      div {
        a {
          text-decoration: none;
        }

        // hide the trash can icon by default
        &.hover:not(:last-child) {
          > a[data-btntype] {
            display: none;
          }
        }
      }

      div,
      a {
        margin-bottom: .2rem;
        padding: .05rem .1rem;

        &.hover:hover {
          background-color: theme-color('primary-medium');
          border-radius: $border-radius;

          // show the trash can icon on-hover
          > a[data-btntype] {
            display: block;
          }
        }
      }
    }

    td.long-text {
      max-width: 225px;
      min-width: 225px;
      white-space: normal;
      word-wrap: break-word;
    }

    td a.btn {
      color: $body-color;

      &:hover {
        text-decoration: none;
      }
    }

    td {
      &:first-child {
        span.row-num::before {
          content: counter(serial-number);  // Display the counter
          counter-increment: serial-number;  // Increment the serial number counter
        }
      }

      &.dropdown-cell {
        max-width: 60px;
        min-width: 60px;
        width: 60px;
      }
      a {
        text-decoration: none;
      }

      textarea {
        height: 100%;
        width: 100%;
      }

      .selectize-input {
        background-color: $white;
        min-width: 160px;
      }
    }
  }
}
