.card-mask {
  z-index: $zindex-card-mask;
}

.card {
  &.card-contrast {
    .card-header,
    .card-body,
    .card-footer {
      padding: 10px;
    }

    .card-subtitle {
      font-size: $font-size-base;

      .badge {
        color: $gray-600;
      }

      a.badge {
        &:hover {
          color: theme-color('secondary');
        }
      }
    }
  }

  &.card-table .card-body {
    padding: 0;
  }

  .tools {
    float: none;

    .disabled {
      display: inline-block;

      .icon {
        color: $gray-400;
        cursor: not-allowed;
      }
    }

    .icon {
      font-size: $font-size-lg;
    }		
  }
}