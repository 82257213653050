.advicesets.embed {
  .main-center {
    header {
      h5 {
        margin-top: 0;
      }

      .list-unstyled {
        li {
          margin-bottom: 8px;
        }
        .badge {
          margin-right: 5px;
        }
      }
    }

    .embed-options {
      hr {
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
      }

      hr + h4 {
        margin-bottom: 1rem;
      }
    }
    
    pre + p {
      margin-top: 25px;
      margin-bottom: 8px;
    }
  }
}