// Functions
@import "includes/functions/opposite-direction";
@import "includes/functions/contrast";
@import "includes/functions/color-correction";

// Mixins
@import "includes/structure/sidebars-push-effect";
@import "includes/mixins/media-queries";
@import "includes/mixins/triangle";
@import "includes/mixins/booking";

// Variables
@import "config/variables";

// Bootstrap components
@import "config/bootstrap-components";

// Plugins source code
// @import "../assets/lib/jquery.niftymodals/sass/style-bootstrap";

//General style
@import "includes/general";

// Fonts
// @import "includes/fonts/roboto";

// Structure
@import "includes/structure/main-wrapper";
@import "includes/structure/top-header";
@import "includes/structure/top-header/icons-nav";
@import "includes/structure/top-header/user-dropdown";
// @import "includes/structure/top-header/notifications-dropdown";
// @import "includes/structure/top-header/connections-dropdown";
@import "includes/structure/left-sidebar";
@import "includes/structure/left-sidebar/progress-widget";
@import "includes/structure/right-sidebar";
@import "includes/structure/right-sidebar/tab-chat";
@import "includes/structure/right-sidebar/tab-todo";
@import "includes/structure/right-sidebar/tab-settings";
@import "includes/structure/main-content";
@import "includes/structure/page-head";
@import "includes/structure/page-aside";
@import "includes/structure/splash-screen";
// @import "includes/structure/scroll-top"; // disabled in app.js config
// @import "includes/structure/sub-navigation";
// @import "includes/structure/mega-menu";
// @import "includes/structure/sub-header";
// @import "includes/structure/footer";

// Forms
@import "includes/forms/switch-button";

// Elements
@import "includes/elements/widgets";
// @import "includes/elements/charts";
// @import "includes/elements/colors";
@import "includes/elements/grid";
@import "includes/elements/icons";
// @import "includes/elements/loader";
@import "includes/elements/code";
@import "includes/elements/divider";
// @import "includes/elements/pricing-tables";
// @import "includes/elements/option-buttons";
@import "includes/elements/timeline";
// @import "includes/elements/table-filters";
// @import "includes/elements/invoice";

// Bootstrap
@import "includes/bootstrap/mixins/alerts";
@import "includes/bootstrap/mixins/buttons";
@import "includes/bootstrap/mixins/tables";
@import "includes/bootstrap/mixins/cards";
@import "includes/bootstrap/mixins/modals";
@import "includes/bootstrap/mixins/dropdown";
@import "includes/bootstrap/mixins/labels";
@import "includes/bootstrap/mixins/badges";
@import "includes/bootstrap/mixins/tabs";
@import "includes/bootstrap/mixins/forms";

@import "includes/bootstrap/images";
@import "includes/bootstrap/code";
@import "includes/bootstrap/buttons";
@import "includes/bootstrap/button-group";
@import "includes/bootstrap/breadcrumbs";
@import "includes/bootstrap/cards";
@import "includes/bootstrap/dropdowns";
@import "includes/bootstrap/collapse";
// @import "includes/bootstrap/custom-forms";
@import "includes/bootstrap/tabs";
@import "includes/bootstrap/tooltip";
@import "includes/bootstrap/modals";
// @import "includes/bootstrap/alerts";
@import "includes/bootstrap/tables";
@import "includes/bootstrap/badges";
@import "includes/bootstrap/popovers";
@import "includes/bootstrap/list-group";
@import "includes/bootstrap/type";
@import "includes/bootstrap/progress-bars";
// @import "includes/bootstrap/pagination";
@import "includes/bootstrap/forms";
@import "includes/bootstrap/input-groups";

// Pages
@import "includes/pages/login";
// @import "includes/pages/404";
@import "includes/pages/sign-up";
@import "includes/pages/forgot-password";
// @import "includes/pages/gallery";
// @import "includes/pages/email";
// @import "includes/pages/code-editor";
// @import "includes/pages/booking";
// @import "includes/pages/documentation";

// Plugins
// @import "includes/plugins/datatables";
// @import "includes/plugins/datetimepicker";
// @import "includes/plugins/dropzone";
// @import "includes/plugins/flot-chart";
// @import "includes/plugins/full-calendar";
@import "includes/plugins/perfect-scrollbar";
// @import "includes/plugins/gritter";
// @import "includes/plugins/multiselect";
// @import "includes/plugins/select2";
// @import "includes/plugins/slider";
// @import "includes/plugins/nestable-lists";
// @import "includes/plugins/mprogress";
// @import "includes/plugins/parsley";
// @import "includes/plugins/summernote";
// @import "includes/plugins/sweetalert2";
// @import "includes/plugins/vector-maps";
// @import "includes/plugins/wizard";

// Widgets
// @import "includes/elements/widgets/calendar";
// @import "includes/elements/widgets/map";
// @import "includes/elements/widgets/tiles";
@import "includes/elements/widgets/user-profile-display";
@import "includes/elements/widgets/user-info-list";
@import "includes/elements/widgets/user-progress";
@import "includes/elements/widgets/user-timeline";
