.has-float-label {
  display: block;
  position: relative;

  label,
  > span {
    position: absolute;
    left: 0;
    top: 0;
    cursor: text;
    font-size: 75%;
    opacity: 1;
    -webkit-transition: all .2s;
            transition: all .2s;
    top: -.5em;
    left: 0.75rem;
    margin-bottom: 0;
    z-index: 3;
    line-height: 1;
    padding: 0 1px;

    &::after {
      content: " ";
      display: block;
      position: absolute;
      background: white;
      height: 2px;
      top: 50%;
      left: -.2em;
      right: -.2em;
      z-index: -1;
    }
  }

  .form-control {
    &::-webkit-input-placeholder,
    &::placeholder {
      opacity: 1;
      -webkit-transition: all .2s;
              transition: all .2s;
    }
    &::-moz-placeholder,
    &:-ms-input-placeholder {
      opacity: 1;
      transition: all .2s;
    }
  }

  .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
    opacity: 0;
  }
  .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
    opacity: 0;
  }
  .form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
    opacity: 0;
  }
  .form-control:placeholder-shown:not(:focus)::placeholder {
    opacity: 0;
  }
  /**
    when input/textarea is empty & placeholder is shown, make it full size in center of input
  */
  .form-control:placeholder-shown:not(:focus) + *:not(.selectize-control) {
    font-size: inherit;
    opacity: .7;
    top: 1.1em;
  }
  .form-control.form-control-sm:placeholder-shown:not(:focus) + *:not(.selectize-control) {
    top: .9em;
  }

  .CodeMirror {
    position: static;
  }

  .form-control.formulabox {
    &:not(.focused) {
      + .CodeMirror-empty {
        + label {
          font-size: inherit;
          top: 1.1em;
        }
      }
      + .CodeMirror-empty .CodeMirror-placeholder {
        display: none;
      }
    }
    &.focused {
      + .CodeMirror-empty {
        + label {
          top: -.35rem;
        }
      }
    }
  }
}
